import React, { useState, useEffect } from "react";
import { useUploadResumeMutation } from "../redux/api/apiSlice";
import styles from "../styles/MyProfile.module.css";
import { IoMdCloudUpload } from "react-icons/io";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  Button,
  Center,
  LoadingOverlay,
  Box,
  Notification,
} from "@mantine/core";
import classes from "../styles/SettingSection.module.css";
import ShramINlogo from "../assets/logos/shraminLogo.png";
import { FaPen } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";
import { IoLanguageSharp } from "react-icons/io5";
import { FaCloudUploadAlt } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { candidateData } from "../redux/userSlice";
import { useDisclosure } from "@mantine/hooks";
import { useEditProfileDetailsMutation } from "../redux/api/apiSlice";
import { Trans } from "@lingui/macro";

const Settings = () => {
  const navigate = useNavigate();

  //const AppVersion = import.meta.env.VITE_VERSION_NAME_CODE


  
  const AppVersion = "25.01.24.1";


  const { logOut, user } = UserAuth();

  const dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = useState(null);
  const [notificationVisible, setNotificationVisible] = useState(false);

  const [loading, { open: openLoading, close: closeLoading }] =
    useDisclosure(false);
  const [editProfile] = useEditProfileDetailsMutation();

  const [uploadResume, { isLoading }] = useUploadResumeMutation();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  useEffect(() => {
    if (selectedFile) {
      openLoading();
      uploadResume(selectedFile)
        .then(() => {
          closeLoading();
          setNotificationVisible(true);
        })
        .catch(() => {
          closeLoading();
        });
    }
  }, [selectedFile, uploadResume]);

  const handleSignOut = async () => {
    try {
      await logOut();
      navigate("/");
      dispatch(candidateData(null));
    } catch (error) {
      console.log(error);
    }
  };

  const editProfilebtn = () => {
    navigate("/myprofile");
  };

  ///////////////

  const handleSuccess = (position) => {
    const { latitude, longitude } = position.coords;
    console.log("latitudelongitude", latitude, longitude);
    editProfile({
      latitude: latitude,
      longitude: longitude,
    });
  };

  const handleError = (error) => {
    console.log(error.message);
  };

  const locationbtn = () => {
    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by your browser");
    } else {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    }
  };

  //////////////

  const myAppliedJobbtn = () => {
    navigate("/appliedjob");
  };

  const changeLanguagebtn = () => {
    //navigate('/myprofile')
  };

  const uploadTextResumebtn = () => {
    // navigate('/myprofile')
  };

  // const locationbtn = () => {
  //   // navigate('/myprofile')
  // };

  return (
    <>
      <Box pos="relative">
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        {notificationVisible && (
          <Notification
            title="Resume Uploaded"
            color="teal"
            onClose={() => setNotificationVisible(false)}
          >
            <Trans>Your resume has been successfully uploaded.</Trans>
          </Notification>
        )}

        <Paper>
          <Group>
            <div style={{ marginRight: "15px", marginLeft: "15px" }}>
              <Center>
                <img src={ShramINlogo} className={classes.siteLogo} />
                <div>
                  <Text mt={10} size="xl" fw={500}>
                    ShramIN Jobs
                  </Text>
                  <Text mt={0} size="md" fw={500}>
                    {AppVersion}
                  </Text>
                </div>
              </Center>

              <div style={{ marginTop: "10px" }}>
                <Button variant="outline" onClick={editProfilebtn}>
                  <Text c="#2258d4" size="sm" mb={5} mt={5} fw={600}>
                    <FaPen style={{ color: "#2258d4", marginRight: "5px" }} />{" "}
                    <Trans>View / Edit Profile</Trans>
                  </Text>
                </Button>
              </div>

              <div style={{ marginTop: "10px" }}>
                <Button variant="outline" onClick={myAppliedJobbtn}>
                  <Text c="#2258d4" size="sm" mb={5} mt={5} fw={600}>
                    <FaStarHalfAlt
                      style={{ color: "#2258d4", marginRight: "5px" }}
                    />{" "}
                    <Trans>My Applied Jobs</Trans>
                  </Text>
                </Button>
              </div>

              {/* <div style={{ marginTop: "10px" }}>
              <Button variant="outline"
                onClick={changeLanguagebtn}>
                <Text c="#2258d4" size="sm" mb={5} mt={5} fw={600}>
                  <IoLanguageSharp style={{ color: "#2258d4", marginRight: "5px" }} /> Change Language
                </Text>
              </Button>
            </div> */}

              {/* <div style={{ marginTop: "10px" }}>
            <Button variant="outline"
              onClick={uploadTextResumebtn}>
              <Text c="#2258d4" size="sm" mb={5} mt={5} fw={600} >
                <FaCloudUploadAlt style={{ color: "#2258d4", marginRight: "5px" }} /> Upload/Replace your Resume
              </Text>
            </Button>
          </div> */}
              <div>
                <label
                  className={styles.upload_resume}
                  style={{ display: "flex", fontWeight: "600" }}
                >
                  <IoMdCloudUpload
                    style={{ marginTop: "4px", fontSize: "20px" }}
                  />
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ opacity: "0", width: "5%" }}
                  ></input>
                  <Trans>Upload/Replace Your Resume</Trans>
                </label>
              </div>

              <div style={{ marginTop: "10px" }}>
                <Button variant="outline" onClick={locationbtn}>
                  <Text c="#2258d4" size="sm" mb={5} mt={5} fw={600}>
                    <FaLocationDot
                      style={{ color: "#2258d4", marginRight: "5px" }}
                    />{" "}
                    <Trans>Turn On Location</Trans>
                  </Text>
                </Button>
              </div>

              <div style={{ marginTop: "10px" }}>
                <Button variant="outline" onClick={handleSignOut}>
                  <Text c="#2258d4" size="sm" mb={5} mt={5} fw={600}>
                    <MdLogout style={{ color: "#2258d4", marginRight: "5px" }} />{" "}
                    <Trans>Logout</Trans>
                  </Text>
                </Button>
              </div>
            </div>
          </Group>
        </Paper>
      </Box>

    </>
  );
};

export default Settings;
