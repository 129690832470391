import React, { useState, useRef } from 'react';
import {
  Card,
  Text,
  Box,
  Button,
  Center,
} from '@mantine/core';
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import './TutorWheel.css';

const TutorWheel = () => {
  const wheelRef = useRef(null);
  const [currentAngle, setCurrentAngle] = useState(300);
  const [isDragging, setIsDragging] = useState(false);
  const startAngleRef = useRef(0);
  const lastAngleRef = useRef(0);
  const segmentCenterAngles = [180, 60, 300];

  const getAngle = (x, y) => {
    const rect = wheelRef.current.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    return Math.atan2(y - centerY, x - centerX) * (180 / Math.PI);
  };

  const snapToSection = (angle) => {
    const normalizedAngle = ((angle % 360) + 360) % 360; // Normalize to [0, 360)
    let closestAngle = segmentCenterAngles.reduce((prev, curr) =>
      Math.abs(curr - normalizedAngle) < Math.abs(prev - normalizedAngle)
        ? curr
        : prev
    );
    return closestAngle;
  };

  const handleDragStart = (x, y) => {
    setIsDragging(true);
    startAngleRef.current = getAngle(x, y);
    lastAngleRef.current = startAngleRef.current;
  };

  const handleDragMove = (x, y) => {
    if (!isDragging) return;

    const currentMouseAngle = getAngle(x, y);
    const angleDifference = currentMouseAngle - lastAngleRef.current;
    if (angleDifference > 0) {
      setCurrentAngle(prevAngle => prevAngle + angleDifference);
    } else {
      setCurrentAngle(prevAngle => prevAngle + angleDifference);
    }

    lastAngleRef.current = currentMouseAngle;
  };


  const handleDragEnd = () => {
    setIsDragging(false);


    const snappedAngle = snapToSection(currentAngle);


    const normalizedCurrentAngle = ((currentAngle % 360) + 360) % 360;
    const normalizedSnappedAngle = ((snappedAngle % 360) + 360) % 360;


    let angleDifference = normalizedSnappedAngle - normalizedCurrentAngle;
    if (angleDifference > 180) angleDifference -= 360;
    if (angleDifference < -180) angleDifference += 360;

    // Apply the shortest rotation
    setCurrentAngle(currentAngle + angleDifference);
  };


  const handleMouseDown = (e) => {
    handleDragStart(e.clientX, e.clientY);
  };

  const handleMouseMove = (e) => {
    handleDragMove(e.clientX, e.clientY);
  };

  const handleMouseUpOrLeave = () => {
    handleDragEnd();
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    handleDragStart(touch.clientX, touch.clientY);
  };

  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    handleDragMove(touch.clientX, touch.clientY);
  };

  const handleTouchEnd = () => {
    handleDragEnd();
  };

  const navigate = useNavigate();
  const arrowBack = () => {
    navigate('/tutoronboard');
  }

  const gotonextscreen = () => {
    navigate('/learnenglish');
  }

  const sections = [
    { name: "Interview Preparation", angle: 120 },
    { name: "Learn English", angle: 0 },
    { name: "Exam Preparation", angle: 240 },
  ];

  const getSelectedSection = () => {
    const topOffset = 178;
    const adjustedAngle = (currentAngle + topOffset) % 360;
    const normalizedAngle = (adjustedAngle + 360) % 360;
    const sectionIndex = Math.round(normalizedAngle / (360 / sections.length)) % sections.length;
    return sections[sectionIndex];
  };

  const selectedSection = getSelectedSection();

  return (
    <div className='main_wheel_container'>
      <div style={{
        position: "fixed",
        width: "100%",
        height: "8%",
        maxWidth: "600px",
        top: "55px",
        padding: "0px",
        display: "grid",
        alignItems: "center",
        justifyContent: "'center",
        alignContent: "center",
        backgroundColor: "white"
      }}>
        <div>
          <IoArrowBack onClick={arrowBack} color='black' size={26} style={{ marginLeft: "10px", marginTop: "5px" }} />
        </div>
        <div style={{ marginTop: "-40px", marginLeft: "50px", marginRight: "50px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
          <Text style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
            <Trans>English Tutor</Trans>
          </Text>
        </div>
      </div>

      <Center style={{ paddingTop: "70px" }}>
        <Text style={{ fontWeight: "500", fontSize: "18px", textAlign: "center" }}>
          <Trans>What do you want to learn today?</Trans>
        </Text>
      </Center>

      <Center style={{ marginTop: "20px" }}>
        <Text style={{ fontWeight: "500", fontSize: "18px", textAlign: "center", color: "#228be6" }}>
          {selectedSection.name}
        </Text>
      </Center>

      <div className='main_wheel'>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '45vh' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              backgroundColor: '#00bfa5',
            }}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUpOrLeave}
            onMouseLeave={handleMouseUpOrLeave}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onTouchStart={handleTouchStart}
          >
            <div className="wheel-container"
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUpOrLeave}
              onMouseLeave={handleMouseUpOrLeave}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <div
                className="wheel"
                ref={wheelRef}
                style={{ transform: `rotate(${currentAngle}deg)`, transition: 'transform 0.1s ease-out' }} // Smooth transition
                onMouseDown={handleMouseDown}
                onTouchStart={handleTouchStart}
              >
                <div className="segment segment1"> Learn English</div>
                <div className="segment segment2"> Interview Preparation</div>
                <div className="segment segment3">Exam Preparation</div>
              </div>
              <div className="arrow"></div>
            </div>
          </Box>
        </div>

        <Center>
          {selectedSection.name === "Learn English" ? (
            <Button onClick={gotonextscreen} style={{ width: "40%" }} mb={"10px"} color='#0180ff' >
              <Trans>Next</Trans>
            </Button>
          ) : (
            <Text style={{ fontSize: "20px", color: "gray" }}><Trans>Coming Soon</Trans></Text>
          )}
        </Center>
      </div>
    </div>
  );
}

export default TutorWheel;
