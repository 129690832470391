import React, { useEffect, useState } from 'react'
import Privatejobcard from '../components/Privatejobcard'
import { useSelector } from 'react-redux'
import { useGetStateJobsForAllQuery } from '../redux/api/apiSlice'
import { Skeleton } from '@mantine/core'
import { useNavigate, useLocation } from 'react-router-dom'
import StateJobCard from './StateJobCardInHome'
import TrendingAllJobsCard from './TrendingAllJobCard'




const StateJobSection = () => {
    const token = useSelector((state) => state?.user.token)
    const [alljobs, setAllJobs] = useState([])
    const [loading, setloading] = useState(true)
    const candidateId = localStorage.getItem('candidateId')
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const stateId = searchParams.get("stateid");


    const { data: jobdata, isLoading, isError, isFetching, refetch } = useGetStateJobsForAllQuery(stateId, {
        skip: !token
    })

    //console.log("wwwweeeeeeffffff777",jobdata)



    useEffect(() => {
        if (token) {
            refetch();
        }
    }, [token, refetch]);


    useEffect(() => {
        if (jobdata?.message == "No jobs found matching your criteria.") {
            setAllJobs([])
            setloading(false)
        } else {
            setAllJobs(jobdata?.content)
            setloading(false)
        }
    }, [jobdata]);




    return (
        <div style={{ width: "100%" }}>
            {loading ? (
                <>
                    <div style={{ marginBottom: "20px" }}>
                        <Skeleton height={50} circle mb="xl" />
                        <Skeleton height={8} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} width="100%" radius="xl" />
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                        <Skeleton height={50} circle mb="xl" />
                        <Skeleton height={8} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} width="100%" radius="xl" />
                    </div>
                    <div>
                        <Skeleton height={50} circle mb="xl" />
                        <Skeleton height={8} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} width="100%" radius="xl" />
                    </div>
                </>
            ) : (
                <>
                    {alljobs?.length > 0 && (
                        alljobs?.map(job => {

                            return (<TrendingAllJobsCard
                                key={job?.id}
                                id={job?.id}
                                jobTitle={job?.jobTitle}
                                trade={job?.trade}
                                companyName={job?.companyName}
                                workType={job?.typeOfWork}
                                education={job?.education}
                                salary={job?.salary}
                                experience={job?.experience}
                                location={job?.location}
                                state={job?.state}
                                logo={job?.logoVirtualPath ? job?.logoVirtualPath : job?.employer?.logoVirtualPath}
                            />

                            )
                        })
                    )}

                    <div style={{ marginTop: "60px" }}>
                        <h6>.</h6>
                    </div>
                </>
            )}
        </div>
    )
}

export default StateJobSection