import React, { createContext, useContext, useState } from "react";

// Create the context
const NavigationContext = createContext();

// Create a provider component
export const NavigationProvider = ({ children }) => {
    const [prevUrl, setPrevUrl] = useState(null);

    const resetPrevUrl = () => setPrevUrl(null);

    return (
        <NavigationContext.Provider value={{ prevUrl, setPrevUrl, resetPrevUrl }}>
            {children}
        </NavigationContext.Provider>
    );
};

// Custom hook to use the context
export const useNavigation = () => useContext(NavigationContext);
