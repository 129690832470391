import React, { useState, useEffect } from 'react'
import styles from '../styles/MainHeader.module.css'
import ShramINlogo from '../assets/logos/shraminLogo.png'
import HelpLogo from '../assets/logos/help.png'
import whatsappLogo from '../assets/logos/whatsapp.png'
import settingLogo from '../assets/logos/setting.png'
import personLogo from '../assets/logos/Person.png'

import { Link } from 'react-router-dom'
import { Trans, t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { I18nProvider, useLingui } from '@lingui/react';
import { defaultLocale, loadCatalog } from '../../i18n'
import { Select } from '@mantine/core'
import { useLocation } from 'react-router-dom'
import { IoIosNotificationsOutline } from "react-icons/io";
import NotificationPermission from '../components/NotificationPermission'
import { UserAuth } from "../context/AuthContext";
import InstallAppbutton from '../components/InstallAppbutton'
import { Button, Text, Group, Center, Modal, Stack, Indicator } from '@mantine/core';
import { IoMdDownload } from "react-icons/io";
import { PiWhatsappLogoLight } from "react-icons/pi";
import { AiOutlineAlert } from "react-icons/ai";
import CustomModalInApp from '../components/CustomInstallModalInApp'
import { FaTelegram } from "react-icons/fa6";
import { FaShareAlt } from "react-icons/fa";
import { BsChatDots } from "react-icons/bs";
import { useNavigate } from 'react-router-dom'
import { useGetAllNotification } from '../services/Queries'




//i18n.activate(loadCatalog);

const languageOptions = [
  { value: 'en', label: 'English' },
  { value: 'hi', label: 'हिंदी' },
  { value: 'gu', label: 'ગુજરાતી' },
  { value: 'ma', label: 'मराठी' },
  { value: 'pu', label: 'ਪੰਜਾਬੀ' },
  { value: 'te', label: 'తెలుగు' },
  { value: 'ta', label: 'தமிழ்' },
  { value: 'be', label: 'বাংলা' },
  { value: 'od', label: 'ଓଡିଆ' },
  { value: 'ka', label: 'ಕನ್ನಡ' },

  // Add more language options as needed
];

const MainHeader = ({ beforeInstallPrompt }) => {


  const { i18n } = useLingui();
  var languageOrigin = localStorage.getItem('languageOrigin');
  const location = useLocation();
  const { pathname } = location
  const [selectedLanguage, setSelectedLanguage] = useState(languageOrigin);
  const [modalOpen, setModalOpen] = useState(false)
  const [openedShare, setOpenedShare] = useState(false);
  const [bellIcon, setBellIcon] = useState(false)
  const navigate = useNavigate();
  const [custominappModal, setCustomInappModal] = useState(false)

  const { user } = UserAuth();
  var trueCountData = localStorage.getItem("notificationCount");

  var trueCount = trueCountData

  useEffect(() => {

    setSelectedLanguage(languageOrigin);
    loadCatalog(languageOrigin);

  }, [selectedLanguage]);

  // useEffect(() => {

  //   //useGetAllNotification(candidateId, token)
  //   console.log("abcd")

  // },[])


  const sharebtn = () => {
    setOpenedShare(true);
  };

  const notificationbtn = () => {
    navigate(`/notificationDetail`)
  }

  const closeShare = () => {
    setOpenedShare(false);
  }

  const sharewhatsappbtn = () => {
    window.open("https://whatsapp.com/channel/0029VaAYCuQEawdmh2V3fH2q")
  }

  const sharetelegrambtn = () => {
    window.open("https://t.me/shraminjobs")
  }

  const handleRedirectbtn = () => {
    setOpenedShare(false)
    navigate(`/chatbot`)
  }


  // const shareallbtn = () => {
  //   console.log("abcd")
  //   // if (navigator.share) {
  //   //   navigator.share({
  //   //     title: 'ShramIN App',
  //   //     text: `SharmIN Jobs App` + `${"\n"}` + `India's best job search app for skilled technicians` + `${"\n"}` + `${"\n"}` ,
  //   //     url: 'https://www.shramin.app/'
  //   //   }).then(() => {
  //   //    // console.log('Thanks for sharing!');
  //   //   }).catch(err => {
  //   //     //console.log("Error while using Web share API:");
  //   //     console.log(err);
  //   //   });
  //   // } else {
  //   //   alert("Browser doesn't support this API !");
  //   // }
  // }

  const shareallbtn = () => {
    //console.log("abcd") 
    if (navigator.share) {
      navigator.share({
        title: 'ShramIN App',
        text: `SharmIN Jobs App` + `${"\n"}` + `India's best job search app for skilled technicians` + `${"\n"}`,
        url: 'https://www.shramin.app/'
      }).then(() => {
        // console.log('Thanks for sharing!');
      }).catch(err => {
        //console.log("Error while using Web share API:");
        console.log(err);
      });
    } else {
      alert("Browser doesn't support this API !");
    }
  };




  const handleLanguageChange = (value) => {
    localStorage.setItem('languageOrigin', value);
    setSelectedLanguage(value);
    loadCatalog(value);
  };


  const modalopen = () => {
    setModalOpen(true)
  }

  const modalClose = () => {
    setModalOpen(false)
  }



  const customInAppModalOpen = () => {
    setCustomInappModal(true)
  }

  const customInAppModalClose = () => {
    setCustomInappModal(false)
  }



  return (
    <>

      {(pathname !== '/joblocation' && pathname !== '/jobLocation' && pathname !== '/createprofileonboarding' && pathname !== '/onboardingbasicinformation' && pathname !== '/whitecollar') ? (

        <>

          <Modal
            withCloseButton={true}
            opened={openedShare}
            onClose={() => setOpenedShare(false)}
            centered

          >


            <Stack >
              <Center position="center" mt="xs">
                <Button onClick={sharewhatsappbtn} style={{ width: '100%', backgroundColor: "green", }}>
                  <PiWhatsappLogoLight
                    style={{ fontSize: "25px", color: "#fff", marginRight: "5px", position: "relative", bottom: "1px", fontWeight: "300" }}
                    cursor="pointer"
                    onClick={sharewhatsappbtn}
                  />
                  <Text c="white" size="md" mb={0} mt={0} fw={500}>
                    <Trans>Join WhatsApp Channel</Trans>
                  </Text>
                </Button>
              </Center>
              <Center>
                <Button onClick={sharetelegrambtn} style={{ width: '100%', backgroundColor: "#0190ff" }}>
                  <FaTelegram
                    style={{ fontSize: "23px", color: "#fff", marginRight: "5px", position: "relative", bottom: "1px", fontWeight: "300" }}
                    cursor="pointer"
                    onClick={sharetelegrambtn}
                  />
                  <Text c="white" size="md" mb={0} mt={0} fw={500}>
                    <Trans>Join Telegram Channel</Trans>
                  </Text>
                </Button>
              </Center>
              <Center>
                <Button onClick={shareallbtn} style={{ width: '100%', backgroundColor: "#000000" }}>
                  <FaShareAlt
                    style={{ fontSize: "18px", color: "#fff", marginRight: "5px", position: "relative", bottom: "1px", fontWeight: "300" }}
                    cursor="pointer"
                    onClick={shareallbtn}
                  />
                  <Text c="white" size="md" mb={0} mt={0} fw={500}>
                    <Trans>Share with Friends</Trans>
                  </Text>
                </Button>
              </Center>

              <Center mb="xl">
                <Button onClick={handleRedirectbtn} style={{ width: '100%', backgroundColor: "#a656de" }}>
                  <BsChatDots
                    style={{ fontSize: "23px", color: "#fff", marginRight: "5px", position: "relative", bottom: "1px", fontWeight: "300" }}
                    cursor="pointer"
                    onClick={handleRedirectbtn}
                  />
                  <Text c="white" size="md" mb={0} mt={0} fw={500}>
                    <Trans>Career Guidance</Trans>
                  </Text>
                </Button>
              </Center>
            </Stack>

          </Modal>




          <div className={styles.container}>
            <Link to="/">
              <img src={ShramINlogo} className={styles.siteLogo} />
            </Link>

            <div className={styles.headerLogoSection}>

              

              {pathname == "/" ? (
                <>
                  <div style={{ width: "150px", marginRight: "10px" }}>
                    <Select
                      key={i18n.locale}
                      data={languageOptions}
                      value={selectedLanguage}
                      onChange={(event) => handleLanguageChange(event)}
                      placeholder={t`Select Language`}
                      searchable={true}
                    />
                  </div>

                </>
              ) : (
                <>
                  <InstallAppbutton beforeInstallPrompt={beforeInstallPrompt} />
                  {!("Notification" in window) && (
                    <Button
                      id='installbutton'
                      variant="filled"
                      size="sm"
                      style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "10px", position: "relative", bottom: "5px" }} onClick={customInAppModalOpen}
                    >
                      <IoMdDownload style={{ marginRight: "5px" }} />
                      <Trans>Install App</Trans>
                    </Button>
                  )}
                </>
              )}






              {/* {("Notification" in window) && (
                  <>
                    {Notification.permission === 'default' && (
                      <AiOutlineAlert  
                      style={{fontSize:"32px", color:"#0180ff", position:"relative", bottom:"5px", fontWeight:"300"}}
                      cursor="pointer"
                      onClick={modalopen}
                      />
                    )}
                  </>
                )} */}



              {/* <IoIosNotificationsOutline 
                      style={{fontSize:"36px", color:"#0180ff", position:"relative", bottom:"3px", fontWeight:"300"}}
                      cursor="pointer"
                       onClick={notificationbtn}
                      /> */}


              {(trueCount > 0) ? (
                <Indicator onClick={notificationbtn} inline label={trueCount} color="red" size={16} offset={5}>
                  <IoIosNotificationsOutline
                    style={{ fontSize: "36px", color: "#0180ff", position: "relative", bottom: "0px", fontWeight: "300" }}
                    cursor="pointer"
                    onClick={notificationbtn}
                  />
                </Indicator>
              ) : (
                <IoIosNotificationsOutline
                  style={{ fontSize: "36px", color: "#0180ff", position: "relative", bottom: "3px", fontWeight: "300" }}
                  cursor="pointer"
                  onClick={notificationbtn}
                />
              )}


              <Link to="/settings">
                <img src={settingLogo} className={styles.headerlogo} />
              </Link>

              <Link to="/myprofile">
                <img src={personLogo} className={styles.headerlogo} />
              </Link>



            </div>

            {/* {modalOpen && <NotificationPermission shouldopen={modalOpen} modalClose={modalClose}/> } */}
            {custominappModal && <CustomModalInApp handleModalClose={customInAppModalClose} />}
          </div>
        </>
      ) : (
        <>
        {(pathname === '/onboardingbasicinformation') ? (
         <div className={styles.container} style={{ display: "flex", gap: "20px", marginTop:"10px" }}>
         <div style={{ width: "100px", marginRight:"-100px" }}>
           <Select
             data={languageOptions}
             value={selectedLanguage}
             onChange={(event) => handleLanguageChange(event)}
             placeholder={t`Select Language`}
             searchable={true}
           />
         </div>
       
         <Text style={{ fontWeight: "bold", fontSize: "20px",  display: "flex", textAlign: "center", color:"black", marginLeft:"auto",marginRight:"auto", alignItems: "center", justifyContent: "center", }}>
           <Trans>Basic Information</Trans>
         </Text>
       </div>
       


          //  <div className={styles.container}>
          //   <div style={{ width: "150px", marginTop: "10px" }}>
          //     <Select
          //       data={languageOptions}
          //       value={selectedLanguage}
          //       onChange={(event) => handleLanguageChange(event)}
          //       placeholder={t`Select Language`}
          //       searchable={true}
          //     />

          //     <div style={{
          //                 textAlign: "center", alignItems: "center", justifyContent: "center"
          //               }}>
          //                 <Text style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>
          //                   <Trans>Basic Information</Trans>
          //                 </Text>
          //               </div>
          //   </div>
          // </div>
        ) : (
          <div className={styles.container}>
          <div style={{ width: "150px", marginTop: "10px" }}>
            <Select
              data={languageOptions}
              value={selectedLanguage}
              onChange={(event) => handleLanguageChange(event)}
              placeholder={t`Select Language`}
              searchable={true}
            />
          </div>
        </div>
        )}
         
        </>
      )}
    </>
  )
}


export default MainHeader
