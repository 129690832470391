import { useContext, createContext, useEffect, useState } from 'react';
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
  getRedirectResult,
} from 'firebase/auth';

import { auth } from '../configFirebase';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { appToken, userData } from '../redux/userSlice';
import { useGetProfileDetailsQuery } from '../redux/api/apiSlice'
import { getMessaging, getToken } from "firebase/messaging";
import { useSelector } from 'react-redux';
import { FacebookAuthProvider } from "firebase/auth";
import { useNavigation } from './NavigationContext';


const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {

  const [user, setUser] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [profileDataFacebook, setProfileDataFacebook] = useState(null);
  const dispatch = useDispatch()
  const [messagingToken, setMessagingToken] = useState('');

  const checked = localStorage.getItem("checked");

  const navigate = useNavigate();
  const { prevUrl } = useNavigation() || { prevUrl: '/' };

  let baseUrl = '';
  let relativeUrl = '/';

  if (prevUrl && prevUrl.startsWith('http')) {
    baseUrl = new URL(prevUrl).origin;
    relativeUrl = prevUrl.replace(baseUrl, "");
  } else {
    console.warn("Invalid or missing prevUrl:", prevUrl);
  }


  const fcmTokenData = useSelector((state) => state?.user.fcmtoken)


  const facebookSignIn = async () => {
    const provider = new FacebookAuthProvider();
    signInWithRedirect(auth, provider)



  };


  useEffect(() => {

    const provider = new FacebookAuthProvider();

    getRedirectResult(auth).then((result) => {


      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      const credential = FacebookAuthProvider.credentialFromResult(result);
      const token = result.user.accessToken;
      //  console.log("tokenwwwwwwwweeee2", token)

      dispatch(appToken(token))
      dispatch(userData(result.user))

      const fetchdataFacebook = async () => {
        const url = import.meta.env.VITE_BASE_URL + '/candidate/signup';
        const apiUrl = fcmTokenData ? `${url}?fcmToken=${fcmTokenData}` : url
        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${token}`,
              "Accept": 'application/json'
            },
          })
          const result = await response.json();
          // console.log(result, "result Data fackbook data in Auth1")
          const { candidateId, userId } = result

          localStorage.setItem('candidateId', candidateId)
          localStorage.setItem('userId', userId)
          profileFetchDataFacebook(token, userId)
          sendWhatsappPermissionInDatabaseFacebook(token)


        } catch (error) {
          console.error('Error fetching data in fbsignin:', error);
        }
      }

      fetchdataFacebook();
      setUser(result.user);


    }).catch((error) => {
      console.error('Error fetching in Facebook Authh', error);
    });

    const profileFetchDataFacebook = async (token, userId) => {


      const url = import.meta.env.VITE_BASE_URL + '/candidate';


      try {
        const response = await fetch(`${url}?userId=${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`,
            "Accept": 'application/json'
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok in ProfileData2');
        }

        const profileResultFacebook = await response.json();

        setProfileDataFacebook(profileResultFacebook);

        // console.log(profileResultFacebook, "profileResult pro  fackbook data")

        if (profileResultFacebook?.tradeId == null) {
          navigate('/onboardingbasicinformation')
        }
        // else if(profileResult?.selected_location_id == null && profileResult?.locationId == null) {
        //   navigate('/joblocation')
        // }
        else if (profileResultFacebook?.name == null) {
          navigate('/createprofileonboarding')
        } else {
          navigate(relativeUrl ? relativeUrl : "/")
        }



      } catch (error) {
        console.error('Error fetching profile data in fbprofile:', error);
      }


    };


    const sendWhatsappPermissionInDatabaseFacebook = async (token) => {


      const url = import.meta.env.VITE_BASE_URL + '/candidate';

      const obj = {};
      obj.whatsAppConsent = checked;
      // console.log(checked,"checkedff")


      try {
        const response = await fetch(`${url}`, {

          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(obj)
        });

        if (!response.ok) {
          throw new Error('Network response was not ok in ProfileData');
        }

        const profileResult = await response.json();

      } catch (error) {
        console.error('Error fetching profile data:', error);
      }


    };



  }, []);




  const googleSignIn = (checked) => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider).then(result => {
      const token = result.user.accessToken
      // console.log("token", token)

      dispatch(appToken(token))
      dispatch(userData(result.user))


      const fetchdata = async () => {
        const url = import.meta.env.VITE_BASE_URL + '/candidate/signup';
        const apiUrl = fcmTokenData ? `${url}?fcmToken=${fcmTokenData}` : url
        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${token}`,
              "Accept": 'application/json'
            },
          })
          const result = await response.json();
          //console.log(result, "resultData")
          const { candidateId, userId } = result

          localStorage.setItem('candidateId', candidateId)
          localStorage.setItem('userId', userId)
          profileFetchData(token, userId)
          sendWhatsappPermissionInDatabase(token)


        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }

      fetchdata();
      setUser(result.user);
    }).catch(err => {
      console.log(err)
    });

    const profileFetchData = async (token, userId) => {


      const url = import.meta.env.VITE_BASE_URL + '/candidate';


      try {
        const response = await fetch(`${url}?userId=${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`,
            "Accept": 'application/json'
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok in ProfileData');
        }

        const profileResult = await response.json();

        setProfileData(profileResult);

        if (profileResult?.tradeId == null && profileResult?.location?.masterName == null) {
          navigate('/onboardingbasicinformation')
        }
        // else if(profileResult?.selected_location_id == null && profileResult?.locationId == null) {
        //   navigate('/joblocation')
        // }
        else if (profileResult?.name == null) {
          navigate('/createprofileonboarding')
        } else {
          navigate(relativeUrl ? relativeUrl : "/")
        }

        // console.log("Candidate API calling for Profile", profileResult);

      } catch (error) {
        console.error('Error fetching profile data:', error);
      }


    };


    const sendWhatsappPermissionInDatabase = async (token) => {


      const url = import.meta.env.VITE_BASE_URL + '/candidate';

      const obj = {};
      obj.whatsAppConsent = checked;


      try {
        const response = await fetch(`${url}`, {

          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(obj)
        });

        if (!response.ok) {
          throw new Error('Network response was not ok in ProfileData');
        }

        const profileResult = await response.json();

      } catch (error) {
        console.error('Error fetching profile data:', error);
      }


    };



  };



  const logOut = () => {
    signOut(auth)
    localStorage.removeItem('candidateId')
    localStorage.removeItem('userId')
  }




  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      dispatch(appToken(currentUser?.accessToken))
      setUser(currentUser);
      // fetchdataFacebook(currentUser?.accessToken)
    });
    return () => {
      unsubscribe();
    };
  }, []);




  return (
    <AuthContext.Provider value={{ googleSignIn, logOut, user, facebookSignIn }}>
      {children}
    </AuthContext.Provider>
  );

};


export const UserAuth = () => {
  return useContext(AuthContext);
};


