import React, { useState, useEffect } from "react";
import styles from "../styles/HomePage.module.css";
import styles1 from "../styles/JobPage.module.css";
import { MdPerson } from "react-icons/md";
import { BsFillBagFill, BsSend, BsSendArrowUp } from "react-icons/bs";
import { FaLifeRing, FaTradeFederation } from "react-icons/fa";
import { MdOutlinePeopleOutline } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import { FaGraduationCap } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import { FaWallet } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { MdOutlineLocationOn } from "react-icons/md";
import { LuAtSign } from "react-icons/lu";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { FaRupeeSign } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import { GiNotebook } from "react-icons/gi";
import { MdOutlinePedalBike } from "react-icons/md";
import { CiClock2 } from "react-icons/ci";
import { CiShare2 } from "react-icons/ci";
import { IoTime } from "react-icons/io5";
import { RxLapTimer } from "react-icons/rx";
import { SlCalender } from "react-icons/sl";
import { Button } from "@mantine/core";
import { AiOutlineShareAlt } from "react-icons/ai";
import { Container } from "@mantine/core";
import JobCard from "../components/JobsCard";
import { IoCallOutline, IoHammerOutline } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { MdReport } from "react-icons/md";
import { FaPerson } from "react-icons/fa6";
import { FaMoneyBills } from "react-icons/fa6";
import { useDisclosure } from "@mantine/hooks";
import { IoCheckboxOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";

import {
  Card,
  Image,
  Text,
  TextInput,
  Group,
  Badge,
  Center,
  Modal,
  Stack,
  Chip,
  Notification,
  Loader,
  Select,
  Spoiler,
  Flex,
  Divider,
  Grid
} from "@mantine/core";
import classes from "../styles/JobsCard.module.css";
import { TbAlertOctagonFilled } from "react-icons/tb";
import SimilarFeaturesCard from "../components/SimilarJobCard";
import {
  useGetJobDescDetailsQuery,
  usePostJobApplyMutation,
  usePostReportJobMutation,
  useGetReportJobQuery,
  useGetjobapplystatusQuery,
  useGetSimilarJobsQuery,
  useGetMoreJobsQuery,
} from "../redux/api/apiSlice";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { Trans, t } from "@lingui/macro";
import { Alert } from "@mantine/core";
import AlertClasses from "../styles/Appliedalert.module.css";
import { useWindowScroll } from "@mantine/hooks";
import { UserAuth } from "../context/AuthContext";
import { Skeleton } from "@mantine/core";
import BestJobCard from "../components/BestJobCard";
import { RadioGroup, Radio } from "@mantine/core";
import SavePrevUrl from "../components/SavePrevUrl";
import { useNavigation } from "../context/NavigationContext";

const demoProps = {
  bg: "var(--mantine-color-body)",
  h: 800,
  w: 550,
  mt: "md",
};

const JobDescription = () => {
  const candidateid = localStorage.getItem("candidateId");
  const [similarMoreJobRefresh, setSimilarMoreJobRefresh] = useState(false);
  const token = useSelector((state) => state?.user.token);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jid = searchParams.get("jid");
  const [applied, setApplied] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const userDetails = useSelector((state) => state?.user?.candidateData);
  const [scroll, scrollTo] = useWindowScroll();
  const { user } = UserAuth();
  const navigate = useNavigate();
  const [similarJobArray, setSimilarJobArray] = useState([]);
  const [loading, setloading] = useState(true);
  const [loadingMore, setloadingMore] = useState(true);
  const [assessmentId, setAssessmentId] = useState(null);
  const [questionId, setQuestionId] = useState(null);
  const [answerId, setAnswerId] = useState(null);
  const [choiceId, setChoiceId] = useState(null);
  const [openedAssessment, setOpenedAssessment] = useState(false);
  const [openedAssessmentAlertEmpty, setOpenedAssessmentAlertEmpty] =
    useState(false);
  const [openedAssessmentAlertEmptySkip, setOpenedAssessmentAlertEmptySkip] =
    useState(false);
  const [
    openedAssessmentAlertEmptySkipToSimilar,
    setOpenedAssessmentAlertEmptySkipToSimilar,
  ] = useState(false);
  const [openedAssessmentSuccess, setOpenedAssessmentSuccess] = useState(false);
  const [openedAssessmentTest, setOpenedAssessmentTest] = useState(false);
  const [openedAssessmentFail, setOpenedAssessmentFail] = useState(false);
  //const [opened, setOpened] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [openedReport, setOpenedReport] = useState(false);
  const [openedReportAlert, setOpenedReportAlert] = useState(false);
  const [openedReportAlertEmpty, setOpenedReportAlertEmpty] = useState(false);

  const [jobData, setJobData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const [valueReportId, setValueReportId] = useState(null);
  const [assessmentData, setAssessmentData] = useState(null);
  const [assessmentOptionData, setAssessmentOptionData] = useState(null);
  const [assessmentQuesAnsData, setAssessmentQuesAnsData] = useState(null);
  const [assessmentResultScoreData, setAssessmentResultScoreData] =
    useState(null);

  const [value, setValue] = useState(null);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [remarkValue, setRemarkValue] = useState(null);
  const [saveUrl, setSaveUrl] = useState(false)
  const [callHr, setCallHr] = useState(null)

  // Check if the current time is between 9 am and 6 pm
  const isWithinCallTime = currentHour >= 9 && currentHour < 18;

  //  console.log("isWithinCallTime", isWithinCallTime)



  const { prevUrl } = useNavigation() || { prevUrl: '/' };


  console.log("orev---" + prevUrl)
  let baseUrl = '';
  let relativeUrl = '/';

  if (prevUrl && prevUrl.startsWith('http')) {
    baseUrl = new URL(prevUrl).origin;
    relativeUrl = prevUrl.replace(baseUrl, "");
  } else {
    console.warn("Invalid or missing prevUrl:", prevUrl);
  }

  console.log("console ---" + relativeUrl)

  const { data: jobapplieddata, refetch: refetch1 } = useGetjobapplystatusQuery(
    { jid, candidateid },
    {
      skip: !token,
    }
  );

  const { data: similarJobdata, refetch: refetch2 } = useGetSimilarJobsQuery(
    { jid },
    {
      skip: !token,
    }
  );

  const { data: moreJobdata, refetch: refetch3 } = useGetMoreJobsQuery(
    { jid },
    {
      skip: !token,
    }
  );

  const { data: reportData, refetch: reportRefetch } = useGetReportJobQuery({
    skip: !token,
  });

  //console.log(reportData, "reportDatacheck")

  const [trigger1, { data: result1, error: error1 }] =
    usePostJobApplyMutation();
  const [trigger2, { data: result2, error: error2 }] =
    usePostJobApplyMutation();
  const [trigger3, { data: result3, error: error3 }] =
    usePostJobApplyMutation();
  const [trigger4, { data: result4, error: error4 }] =
    usePostJobApplyMutation();
  const [trigger5, { data: result5, error: error5 }] =
    usePostJobApplyMutation();

  const [triggerReportJob, { data: resultReport, error: errorReport }] =
    usePostReportJobMutation();


  useEffect(() => {

    const currentUrl = window.location.href;
    const formattedUrl = currentUrl.replace(/\?([^?]*)\?/, "?$1&");

    const searchParams = new URLSearchParams(new URL(formattedUrl).search);
    let hasCall = searchParams.has("call")


    if (prevUrl) {
      // if (hasCall) {
      //   isWithinCallTime ? handleCallHR() : handleCallHRNew()
      // } else {
      // handleJobApply()
      // }

      handleJobApply()
    }
  }, [])

  useEffect(() => {
    if (user && jid) {
      fetchAssessmentData();
    }
  }, [user, jid, currentPage]);

  ///////////////////////Assessment/////////////////////

  const fetchAssessmentData = async () => {
    const url = import.meta.env.VITE_BASE_URL + "/candidate/question";

    //const url = 'https://shramin-dev.azurewebsites.net/candidate/question';

    try {
      const response = await fetch(
        `${url}?jobId=${jid}&page=${currentPage}&size=1`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.accessToken}`,
            Accept: "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok in assessmentData1");
      }
      const assessmentResult = await response.json();
      var optionId = assessmentResult?.content?.map((item) => item?.id);
      var assessmentIdValue = assessmentResult?.content?.map(
        (item) => item?.assessmentId
      );
      setAssessmentId(assessmentIdValue);
      setAssessmentData(assessmentResult?.content);
      if (optionId?.length > 0) {
        fetchAssessmentOptionData(optionId);
        // if(currentPage > 0){
        //   setCurrentPage((prevPage) => prevPage + 1);
        // }
      }
    } catch (error) {
      console.error("Error fetching assessment data:", error);
    }
  };

  const fetchAssessmentOptionData = async (optionId) => {
    const url = import.meta.env.VITE_BASE_URL + "/candidate/question/choice/";

    //const url = 'https://shramin-dev.azurewebsites.net/candidate/question/choice/';

    try {
      const response = await fetch(`${url}${optionId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
          Accept: "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok in assessmentData2");
      }
      const assessmentOptionResult = await response.json();
      setAssessmentOptionData(assessmentOptionResult);
    } catch (error) {
      console.error("Error fetching assessment data:", error);
    }
  };

  const fetchAssessmentQuesAnsData = async () => {
    const url = import.meta.env.VITE_BASE_URL + "/candidate/answer/";

    //const url = 'https://shramin-dev.azurewebsites.net/candidate/answer/';

    try {
      const response = await fetch(
        `${url}${jid}?questionId=${questionId}&answerId=${answerId}&choiceId=${choiceId}&candidateId=${candidateid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.accessToken}`,
            Accept: "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          "Network response was not ok in assessmentQuesAnsResult3"
        );
      }
      const assessmentQuesAnsResult = await response.json();
      // fetchAssessmentData()
      setAssessmentQuesAnsData(assessmentQuesAnsResult);
      if (currentPage == 4) {
        fetchAssessmentResultData();
      }
    } catch (error) {
      console.error("Error fetching assessmentQuesAnsResultm data:", error);
    }
  };

  const fetchAssessmentResultData = async () => {
    const url = import.meta.env.VITE_BASE_URL + "/candidate/assessment/result/";

    //const url = 'https://shramin-dev.azurewebsites.net/candidate/assessment/result/';

    try {
      const response = await fetch(
        `${url}${jid}?assessmentId=${assessmentId}&candidateId=${candidateid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.accessToken}`,
            Accept: "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok in assessmentResultData4");
      }
      const assessmentResultData = await response.json();
      const formattedNumber = assessmentResultData?.score?.toFixed(2);
      setAssessmentResultScoreData(formattedNumber);
      handleJobApply();
      if (formattedNumber >= 25) {
        setOpenedAssessmentSuccess(true);
      } else {
        setOpenedAssessmentFail(true);
      }
    } catch (error) {
      console.error("Error fetching assessmentResultData data:", error);
    }
  };

  /////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const fetchJobdescriptiondata = async () => {
      const response = await fetch(
        `${import.meta.env.VITE_BASE_URL}/public/jobs/${jid}`
      );
      const data = await response.json();
      setJobData(data);

      //console.log("aaaassssssseeeeqqq",data)
    };

    fetchJobdescriptiondata();
    //setAssessmentId(null)

    // if (token) {
    //   if (candidateid && jid) {
    //     const data = {
    //       candidateId: candidateid,
    //       jobId: jid,
    //       viewJob: "Y",
    //       //  applyJob: null
    //     }
    //     trigger3(data)
    //   }
    // }
  }, [similarMoreJobRefresh]);

  useEffect(() => {
    if (token) {
      refetch1();
      if (jid) {
        refetch2();
        refetch3();
        reportRefetch();
      }
    }
  }, [token, refetch1, refetch2, refetch3, reportRefetch]);

  useEffect(() => {
    // console.log("bbbbbbbbbbb++++++++",jid, candidateid, token)

    if (token) {
      if (candidateid && jid) {
        //console.log("aaaaaaaaaa++++++++",jid, candidateid, token)
        const data = {
          candidateId: candidateid,
          jobId: jid,
          viewJob: "Y",
          //  applyJob: null
        };
        trigger3(data);
      }
    }
  }, [similarMoreJobRefresh, token]);

  let jobdate = jobData?.createdAt;

  let passport = jobData?.passport;

  if (jobData !== undefined || jobData !== null) {
    jobdate = jobdate?.slice(0, 10).split("-").reverse().join("-");
  }

  useEffect(() => {
    if (jobapplieddata) {
      if (jobapplieddata.appliedJob) {
        setApplied(true);
      } else {
        setApplied(false);
      }
    }
  }, [jobapplieddata]);

  useEffect(() => {
    if (similarJobdata) {
      setloading(false);
      //setJobData(null)
    }
  }, [similarJobdata]);

  useEffect(() => {
    if (moreJobdata) {
      setloadingMore(false);
      //setJobData(null)
    }
  }, [moreJobdata]);

  useEffect(() => {
    if (result1) {
      if (result1.message == "success") {
        setApplied(true);
      }
    }
  }, [result1]);

  const checkProfileScore = () => {
    setShowAlert(true);
  };

  const scrollFun = () => {
    setSimilarMoreJobRefresh(!similarMoreJobRefresh);
    scrollTo({ y: 0 });
    setShowAlertSuccess(false);
    setShowAlert(false);
  };

  const handleJobApply = () => {
    const data = {
      candidateId: candidateid,
      jobId: jid,
      applyJob: "Y",
    };
    if (userDetails?.profileScore < 40 && prevUrl == null) {
      checkProfileScore();
      scrollTo({ y: 0 });
    } else {
      trigger1(data);
      setShowAlertSuccess(true);
    }
  };

  // const handleJobApplyConfirm = () => {
  //   const data = {
  //     candidateId: candidateid,
  //     jobId: jid,
  //     applyJob: "Y"
  //   }
  //   trigger1(data)
  //   setShowAlertSuccess(true)
  // }

  const handleCallHRNew = () => {

    open();
    const data = {
      candidateId: candidateid,
      jobId: jid,
      callJob: "Y",
      // applyJob: null
    };
    if (userDetails?.profileScore < 40) {
      checkProfileScore();
      scrollTo({ y: 0 });
    } else {
      trigger2(data);
    }
  };

  const handleCallHR = () => {

    window.open("tel:" + jobData?.employerPhoneNo);

    const data = {
      candidateId: candidateid,
      jobId: jid,
      callJob: "Y",
      // applyJob: null
    };
    if (userDetails?.profileScore < 40) {
      checkProfileScore();
      scrollTo({ y: 0 });
    } else {
      trigger2(data);
    }
  };

  const handleGovtJobApply = () => {
    const data = {
      candidateId: candidateid,
      jobId: jid,
      applyJob: "Y",
    };
    if (userDetails?.profileScore < 40) {
      checkProfileScore();
      scrollTo({ y: 0 });
    } else {
      trigger4(data);
    }
  };

  const handleWalkInJobApply = () => {
    const data = {
      candidateId: candidateid,
      jobId: jid,
      applyJob: "Y",
    };
    if (userDetails?.profileScore < 40) {
      checkProfileScore();
      scrollTo({ y: 0 });
    } else {
      trigger5(data);
    }
  };

  const assessmentbtn = () => {
    setSaveUrl(true)
    if (assessmentId?.length > 0) {
      fetchAssessmentData();
      setOpenedAssessmentTest(true);
    } else {
      // alert('No Trade')
      handleJobApply();
    }
  };

  const selectedOption = (item) => {
    setSelectedOptionId(item?.id);
    setQuestionId(item?.questionId);
    setAnswerId(item?.id);
    setChoiceId(item?.id);
  };

  const handleNextPage = () => {
    if (selectedOptionId) {
      setSelectedOptionId(null);
      setCurrentPage((prevPage) => prevPage + 1);
      fetchAssessmentQuesAnsData();
    } else {
      setOpenedAssessmentAlertEmpty(true);
    }
  };

  const handleSubmit = () => {
    if (selectedOptionId) {
      fetchAssessmentQuesAnsData();
      //fetchAssessmentResultData()
      setOpenedAssessment(false);
      //handleJobApply()
    } else {
      setOpenedAssessmentAlertEmpty(true);
    }
    // setCurrentPage((prevPage) => prevPage + 1);
  };

  const closeAssessmentAlertEmpty = () => {
    setOpenedAssessmentAlertEmpty(false);
  };

  const closeModalForAssessmentSuccess = () => {
    setOpenedAssessmentSuccess(false);
    setCurrentPage(0);
    setAssessmentId(null);
    //handleJobApplyConfirm()
    similarJobAfterApply();
  };

  const closeModalForAssessmentFail = () => {
    setCurrentPage(0);
    setAssessmentId(null);
    setOpenedAssessmentFail(false);
    // handleJobApplyConfirm()
    similarJobAfterApply();
  };

  const allAssessmentDataClose = () => {
    setOpenedAssessment(false);
    setCurrentPage(0);
    //setAssessmentId(null)
    setQuestionId(null);
    setAnswerId(null);
    setChoiceId(null);
  };

  const reportbtn = () => {
    setOpenedReport(true);
  };

  const closeReport = () => {
    setOpenedReport(false);
  };

  const reportbtnAlert = () => {
    setOpenedReportAlert(true);
  };

  const closeReportAlert = () => {
    setOpenedReportAlert(false);
  };

  const handleAssessmentTest = () => {
    setOpenedAssessmentTest(false);
    setOpenedAssessment(true);
  };

  const closeAssessmentTestSkip = () => {
    setOpenedAssessmentAlertEmptySkip(true);
  };

  const closeAssessmentSkipTest = () => {
    setOpenedAssessmentAlertEmptySkip(false);
    setOpenedAssessmentTest(false);
    //similarJobAfterApply()
    setOpenedAssessmentAlertEmptySkipToSimilar(true);
    handleJobApply();
  };

  const closeModalForAssessmentSkiptoSimilar = () => {
    // setOpenedAssessmentTest(false)
    // setOpenedAssessmentAlertEmptySkip(false)
    setOpenedAssessmentAlertEmptySkipToSimilar(false);
    similarJobAfterApply();
  };

  const similarJobAfterApply = () => {
    // {similarJobdata?.length > 0 && (
    //   similarJobdata?.map(job => {(job[0]?.id)})
    // )}
    const item =
      similarJobdata && similarJobdata?.length > 0
        ? similarJobdata[0]?.id
        : null;
    scrollTo({ y: 0 });
    setShowAlertSuccess(false);
    setSimilarMoreJobRefresh(!similarMoreJobRefresh);
    navigate(`/jobdescription?jid=${item}`);
  };

  const closeAssessmentNoSkipTest = () => {
    setOpenedAssessmentAlertEmptySkip(false);
  };

  const closeReportAlertEmpty = () => {
    setOpenedReportAlertEmpty(false);
  };

  const handleChange = (newValue) => {
    setValueReportId(newValue?.id);
    setValue(newValue?.masterName);
  };

  const handleReportJobAlert = () => {
    //reportbtnAlert()

    if (valueReportId && remarkValue) {
      // setOpenedReportAlert(true)
      reportbtnAlert();
    } else {
      setOpenedReportAlertEmpty(true);
    }
  };

  const handleReportJob = () => {
    const data = {
      candidateId: candidateid,
      jobId: jid,
      reportReasonId: valueReportId,
      reportRemarks: remarkValue,
    };
    triggerReportJob(data);

    setOpenedReportAlert(false);
    setOpenedReport(false);
  };

  //////////////Private Job///////////

  const shareToWhatsAppPrivate = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "ShramIN App",
          text:
            `Job on SharmIN App` +
            `${"\n"}` +
            `Job Details:` +
            `${"\n"}` +
            `${"\n"}` +
            `Job Title` +
            `${"\n"}` +
            `${jobData?.jobTitle}` +
            `${"\n"}` +
            `${"\n"}` +
            `Company Name` +
            `${"\n"}` +
            `${jobData?.companyName}` +
            `${"\n"}` +
            `${"\n"}` +
            `Trade` +
            `${"\n"}` +
            `${jobData?.trade?.masterName}` +
            `${"\n"}` +
            `${"\n"}` +
            `Salary` +
            `${"\n"}` +
            `${jobData?.salary?.masterName}` +
            `${"\n"}` +
            `${"\n"}` +
            `Job Location` +
            `${"\n"}` +
            `${jobData?.location.masterName}` +
            `${"\n"}` +
            `${"\n"}` +
            `Apply Here` +
            `${"\n"}`,
          url:
            jobData?.deepLink === null
              ? "https://www.shramin.app/"
              : jobData?.deepLink + "?jobId=" + candidateid,
        })
        .then(() => {
          // console.log('Thanks for sharing!');
        })
        .catch((err) => {
          //console.log("Error while using Web share API:");
          console.log(err);
        });
    } else {
      alert("Browser doesn't support this API !");
    }
  };

  const shareToWhatsAppGovt = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "ShramIN App",
          text:
            `Job on SharmIN App` +
            `${"\n"}` +
            `Job Details:` +
            `${"\n"}` +
            `${"\n"}` +
            `Job Title` +
            `${"\n"}` +
            `${jobData?.jobTitle}` +
            `${"\n"}` +
            `${"\n"}` +
            `Company Name` +
            `${"\n"}` +
            `${jobData?.companyName}` +
            `${"\n"}` +
            `${"\n"}` +
            `Trade` +
            `${"\n"}` +
            `${jobData?.trade?.masterName}` +
            `${"\n"}` +
            `${"\n"}` +
            `Salary` +
            `${"\n"}` +
            `${jobData?.salary?.masterName}` +
            `${"\n"}` +
            `${"\n"}` +
            `Job Location` +
            `${"\n"}` +
            `${jobData?.location.masterName}` +
            `${"\n"}` +
            `${"\n"}` +
            `Apply Here` +
            `${"\n"}`,
          url:
            jobData?.deepLink === null
              ? "https://www.shramin.app/"
              : jobData?.deepLink + "?jobId=" + candidateid,
        })
        .then(() => {
          // console.log('Thanks for sharing!');
        })
        .catch((err) => {
          //console.log("Error while using Web share API:");
          console.log(err);
        });
    } else {
      alert("Browser doesn't support this API !");
    }
  };

  const shareToWhatsAppWalkIn = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "ShramIN App",
          text:
            `Job on SharmIN App` +
            `${"\n"}` +
            `Job Details:` +
            `${"\n"}` +
            `${"\n"}` +
            `Job Title` +
            `${"\n"}` +
            `${jobData?.jobTitle}` +
            `${"\n"}` +
            `${"\n"}` +
            `Company Name` +
            `${"\n"}` +
            `${jobData?.companyName}` +
            `${"\n"}` +
            `${"\n"}` +
            `Trade` +
            `${"\n"}` +
            `${jobData?.trade?.masterName}` +
            `${"\n"}` +
            `${"\n"}` +
            `Salary` +
            `${"\n"}` +
            `${jobData?.salary?.masterName}` +
            `${"\n"}` +
            `${"\n"}` +
            `Job Location` +
            `${"\n"}` +
            `${jobData?.location.masterName}` +
            `${"\n"}` +
            `${"\n"}` +
            `Apply Here` +
            `${"\n"}`,
          url:
            jobData?.deepLink === null
              ? "https://www.shramin.app/"
              : jobData?.deepLink + "?jobId=" + candidateid,
        })
        .then(() => {
          // console.log('Thanks for sharing!');
        })
        .catch((err) => {
          //console.log("Error while using Web share API:");
          console.log(err);
        });
    } else {
      alert("Browser doesn't support this API !");
    }
  };

  const handleRedirectLogin = () => {
    setSaveUrl(true)
    setTimeout(() => {
      navigate("/");
    }, 1000)
  };

  return (
    <>
      <div>
        {jobData != null ? (
          <div>
            <Modal withCloseButton={false} opened={opened} centered>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "10% 90%",
                  gridGap: "0px",
                }}
              >
                <div style={{ textAlign: "center" }}></div>
                <Text align="left" fw={500} size="sm" mb="md">
                  <Trans>Please Call to HR between 9 AM to 6 PM</Trans>
                </Text>
              </div>

              <Stack align="center">
                <Group position="center" mt="md">
                  <Button onClick={close}>
                    <Trans>OK</Trans>
                  </Button>
                </Group>
              </Stack>
            </Modal>

            {/* /////////////////Assessment Test/////////// */}

            <Modal
              withCloseButton={true}
              opened={openedAssessmentTest}
              onClose={() => setOpenedAssessmentTest(false)}
              centered
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "10% 90%",
                  gridGap: "0px",
                }}
              >
                <div style={{ textAlign: "center" }}></div>
              </div>

              <div>
                <Card withBorder radius="md">
                  <div style={{ marginTop: "0px" }}>
                    <Text c="#4A4EB7" size="md" fw={600}>
                      {jobData?.jobTitle}
                    </Text>
                    <Text c="#4A4EB7" size="sm" fw={600}>
                      {jobData?.companyName}
                    </Text>
                  </div>
                </Card>
              </div>

              <div style={{ marginTop: "15px" }}>
                <Card withBorder radius="md">
                  <div style={{ marginTop: "0px" }}>
                    <Text size="sm" fw={600}>
                      <Trans>
                        Taking this quick test can help show us if you're a good
                        fit for the job! It won't take long and will give us a
                        chance to see your skills.{" "}
                      </Trans>
                      {/* असेसमेंट पूरा करने से आपकी महत्वता बढ़ सकती है। यह सीधे आपके स्किल्स और नौकरी के लिए उपयोगिता को दिखाता है। कृपया अपना असेसमेंट पूरा करें। */}
                    </Text>
                  </div>
                </Card>
              </div>

              <div
                style={{
                  marginTop: "15px",
                  justifyContent: "center",
                  justifyItems: "center",
                  alignSelf: "center",
                }}
              >
                <Card withBorder radius="md">
                  <div
                    style={{
                      marginTop: "0px",
                      justifyContent: "center",
                      alignSelf: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Text size="sm" fw={600}>
                      <Trans>
                        Please complete the test to continue your application.
                      </Trans>
                      {/* क्या आप परीक्षा देना चाहते हैं? जिससे आपको यह नौकरी पाने में आसानी हो। */}
                    </Text>
                  </div>

                  {/* <div style={{ marginTop: "20px", justifyContent: "center", alignSelf: "center", alignItems:"center", }}>
                              <Button 
                              //onClick={closeModalForAssessmentSuccess}
                              >
                                Skip
                              </Button>
                            </div>   */}

                  <Stack align="center">
                    <Group position="center" mt="md">
                      <Button
                        style={{
                          marginTop: "20px",
                          borderColor: "#1F7A8C",
                          width: "120px",
                        }}
                        variant="outline"
                        onClick={closeAssessmentTestSkip}
                      >
                        <Text c="#1F7A8C" size="md" mb={0} mt={0} fw={500}>
                          <Trans>Skip</Trans>
                        </Text>
                      </Button>

                      <Button
                        variant="filled"
                        style={{
                          marginTop: "20px",
                          backgroundColor: "#1F7A8C",
                          width: "120px",
                        }}
                        onClick={handleAssessmentTest}
                      >
                        <Text c="white" size="md" mb={0} mt={0} fw={500}>
                          <Trans>Start Test</Trans>
                        </Text>
                      </Button>
                    </Group>
                  </Stack>
                </Card>
              </div>
            </Modal>

            {/* //////////////////Skip Alert Assessment/////////////////////// */}

            <Modal
              withCloseButton={true}
              opened={openedAssessmentAlertEmptySkip}
              onClose={() => setOpenedAssessmentAlertEmptySkip(false)}
              centered
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "0% 100%",
                  gridGap: "0px",
                }}
              >
                <div style={{ textAlign: "center" }}></div>
                <Text
                  style={{ color: "#1F7A8C", textAlign: "center" }}
                  fw={500}
                  size="lg"
                  mb="md"
                >
                  <Trans>
                    Taking the short test is important to be considered for this
                    job.
                  </Trans>
                  <br></br>
                  <Trans>Are you sure you want to skip it?</Trans>
                  {/* <br></br>
                अगर आप परीक्षा नहीं देंगे तो आपको नौकरी मिलने की संभावना बहुत कम हो जाएगी।
                क्या आप अब भी स्किप करना चाहते हैं?*/}
                </Text>
              </div>

              <Stack align="center">
                <Group position="center" mt="md">
                  <Button
                    style={{
                      marginTop: "0px",
                      backgroundColor: "red",
                      width: "90px",
                    }}
                    variant="filled"
                    onClick={closeAssessmentSkipTest}
                  >
                    <Text c="white" size="md" mb={0} mt={0} fw={500}>
                      <Trans>Yes</Trans>
                    </Text>
                  </Button>
                  <Button
                    style={{
                      marginTop: "0px",
                      backgroundColor: "green",
                      width: "90px",
                    }}
                    variant="filled"
                    onClick={closeAssessmentNoSkipTest}
                  >
                    <Text c="white" size="md" mb={0} mt={0} fw={500}>
                      <Trans>No</Trans>
                    </Text>
                  </Button>
                </Group>
              </Stack>
            </Modal>

            {/* ///////////////////Skip To Similar Job/////////////// */}

            <Modal
              withCloseButton={false}
              opened={openedAssessmentAlertEmptySkipToSimilar}
              onClose={() => setOpenedAssessmentAlertEmptySkipToSimilar(false)}
              centered
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "0% 100%",
                  gridGap: "0px",
                }}
              >
                <div style={{ textAlign: "center" }}></div>
                <Text
                  style={{
                    color: "#1F7A8C",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                  fw={500}
                  size="lg"
                  mb="md"
                >
                  <Trans>
                    Application received! See similar jobs you might like.
                  </Trans>
                </Text>
              </div>

              <Stack align="center">
                <Group position="center" mt="md">
                  <div
                    style={{
                      marginTop: "20px",
                      justifyContent: "center",
                      alignSelf: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button onClick={closeModalForAssessmentSkiptoSimilar}>
                      <Trans>View Similar Job</Trans>
                    </Button>
                  </div>
                </Group>
              </Stack>
            </Modal>

            {/* /////////////////Assessment/////////// */}

            <Modal
              withCloseButton={true}
              opened={openedAssessment}
              onClose={() => allAssessmentDataClose()}
              centered
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "10% 90%",
                  gridGap: "0px",
                }}
              >
                <div style={{ textAlign: "center" }}></div>
              </div>

              {/* <div>
                <Card withBorder radius="md">
                            <div style={{ marginTop: "0px" }}>
                              <Text c= '#4A4EB7' size="md" fw={600}>
                                 {jobData?.jobTitle}
                              </Text>
                              <Text c= '#4A4EB7' size="sm" fw={600}>
                               {jobData?.companyName}
                              </Text>
                            </div>   
                </Card>
             </div> */}

              {/* <div style={{ marginTop: "15px" }}>
                <Card withBorder radius="md">
                            <div style={{ marginTop: "0px" }}>
                              <Text size="sm" fw={600}>
                              Completing the assessement can boost your relevance to employer. It's directly showcase your skills and fit for the job. Please complete Your assessment.
                              </Text>
                            </div>   
                </Card>
             </div> */}

              {/* {currentPage <= 4 && (                
                            <div style={{ marginTop: "10px", justifyContent: "center", alignSelf: "center", alignItems:"center", textAlign: "center" }}>
                              <Text c= '#4A4EB7' size="md" fw={600}>
                                 {currentPage}/5
                              </Text>
                            </div>   
                             )} */}

              <div style={{ marginTop: "15px" }}>
                <Card withBorder radius="md">
                  <div style={{ marginTop: "0px" }}>
                    {currentPage <= 4 && (
                      <div
                        style={{
                          marginTop: "0px",
                          justifyContent: "center",
                          alignSelf: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Text c="#2258d4" size="md" fw={600}>
                          {currentPage + 1}/5
                        </Text>
                      </div>
                    )}
                    {assessmentData?.map((item, index) => (
                      <div key={index} style={{ marginTop: "7px" }}>
                        <Text
                          style={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          <Trans id={`assessment.${item.id}`}>
                            {item?.questionText}
                          </Trans>
                          {/* {item?.id}.{item?.questionText} */}
                        </Text>
                      </div>
                    ))}
                  </div>

                  <div fullWidth style={{ marginTop: "10px" }}>
                    {assessmentOptionData?.map((item, index) => (
                      <label
                        key={index}
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          marginLeft: "8px",
                          marginRight: "8px",
                          marginBottom: "8px",
                          //  width: "100%",
                        }}
                      >
                        <Chip
                          checked={selectedOptionId === item.id}
                          onChange={() => selectedOption(item)}
                          size="lg"
                        // onClick={() => alert(`Selected Option ID: ${item.id}`)}
                        >
                          <Text
                            style={{
                              textAlign: "center",
                              textWrap: "wrap",
                              marginLeft: "0px",
                              fontSize: "14px",
                              paddingTop: "18px",
                              height: "60px",
                              width: "240px",
                            }}
                          >
                            {item?.choice_text}
                          </Text>
                        </Chip>
                      </label>
                    ))}
                  </div>
                </Card>
              </div>

              <Center style={{ marginTop: "20px" }}>
                {currentPage < 4 ? (
                  <Button
                    onClick={handleNextPage}
                    style={{
                      marginTop: "25px",
                      backgroundColor: "#1F7A8C",
                      color: "white",
                      padding: "10px 5%", // Responsive width
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      width: "60%", // Responsive width
                      margin: "0 auto", // Center horizontally
                    }}
                  >
                    <Trans>Next Question</Trans>
                  </Button>
                ) : (
                  <Button
                    onClick={handleSubmit}
                    style={{
                      marginTop: "25px",
                      backgroundColor: "#3B5998",
                      color: "white",
                      padding: "10px 5%", // Responsive width
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      width: "60%", // Responsive width
                      margin: "0 auto", // Center horizontally
                    }}
                  >
                    <Trans>Submit</Trans>
                  </Button>
                )}
              </Center>
            </Modal>

            {/* //////////////////Empty Alert Assessment/////////////////////// */}

            <Modal
              withCloseButton={false}
              opened={openedAssessmentAlertEmpty}
              onClose={() => setOpenedAssessmentAlertEmpty(false)}
              centered
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "10% 90%",
                  gridGap: "0px",
                }}
              >
                <div style={{ textAlign: "center" }}></div>
                <Text
                  style={{ color: "#1F7A8C", textAlign: "center" }}
                  fw={500}
                  size="lg"
                  mb="md"
                >
                  <Trans>Please Select Option</Trans>
                </Text>
              </div>

              <Stack align="center">
                <Group position="center" mt="md">
                  <Button
                    style={{ marginTop: "0px", borderColor: "#1F7A8C" }}
                    variant="outline"
                    onClick={closeAssessmentAlertEmpty}
                  >
                    <Text c="#1F7A8C" size="md" mb={0} mt={0} fw={500}>
                      <Trans>OK</Trans>
                    </Text>
                  </Button>
                </Group>
              </Stack>
            </Modal>

            {/* /////////////////Assessment Success/////////// */}

            <Modal
              withCloseButton={false}
              opened={openedAssessmentSuccess}
              onClose={() => setOpenedAssessmentSuccess(false)}
              centered
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "10% 90%",
                  gridGap: "0px",
                }}
              >
                <div style={{ textAlign: "center" }}></div>
              </div>

              <div>
                <Card withBorder radius="md">
                  <div style={{ marginTop: "0px" }}>
                    <Text c="#4A4EB7" size="md" fw={600}>
                      {jobData?.jobTitle}
                    </Text>
                    <Text c="#4A4EB7" size="sm" fw={600}>
                      {jobData?.companyName}
                    </Text>
                  </div>
                </Card>
              </div>

              <div
                style={{
                  marginTop: "15px",
                  justifyContent: "center",
                  justifyItems: "center",
                  alignSelf: "center",
                }}
              >
                <Card withBorder radius="md">
                  <div
                    style={{
                      marginTop: "0px",
                      justifyContent: "center",
                      alignSelf: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Text size="sm" fw={600}>
                      <Trans>You have successfully applied for this job.</Trans>
                    </Text>
                  </div>
                  <div
                    style={{
                      marginTop: "15px",
                      justifyContent: "center",
                      alignSelf: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Text c="green" size="sm" fw={600}>
                      <Trans>Your Test Score</Trans> :{" "}
                      {assessmentResultScoreData}%
                    </Text>
                  </div>

                  <div
                    style={{
                      marginTop: "20px",
                      justifyContent: "center",
                      alignSelf: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button onClick={closeModalForAssessmentSuccess}>
                      <Trans>View Similar Job</Trans>
                    </Button>
                  </div>
                </Card>
              </div>
            </Modal>

            {/* /////////////////Assessment Fail/////////// */}

            <Modal
              withCloseButton={false}
              opened={openedAssessmentFail}
              onClose={() => setOpenedAssessmentFail(false)}
              centered
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "10% 90%",
                  gridGap: "0px",
                }}
              >
                <div style={{ textAlign: "center" }}></div>
              </div>

              <div>
                <Card withBorder radius="md">
                  <div style={{ marginTop: "0px" }}>
                    <Text c="#4A4EB7" size="md" fw={600}>
                      {jobData?.jobTitle}
                    </Text>
                    <Text c="#4A4EB7" size="sm" fw={600}>
                      {jobData?.companyName}
                    </Text>
                  </div>
                </Card>
              </div>

              <div
                style={{
                  marginTop: "15px",
                  justifyContent: "center",
                  justifyItems: "center",
                  alignSelf: "center",
                }}
              >
                <Card withBorder radius="md">
                  <div
                    style={{
                      marginTop: "0px",
                      justifyContent: "center",
                      alignSelf: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Text c="red" size="sm" fw={600}>
                      <Trans>Your Test Score</Trans> :{" "}
                      {assessmentResultScoreData}%
                    </Text>
                  </div>
                  <div
                    style={{
                      marginTop: "15px",
                      justifyContent: "center",
                      alignSelf: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Text size="sm" fw={600}>
                      <Trans>
                        Application received! See similar jobs you might like.
                      </Trans>
                    </Text>
                  </div>

                  <div
                    style={{
                      marginTop: "20px",
                      justifyContent: "center",
                      alignSelf: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button onClick={closeModalForAssessmentFail}>
                      <Trans>View Similar Job</Trans>
                    </Button>
                  </div>
                </Card>
              </div>
            </Modal>

            {/* /////////////////report/////////// */}

            <Modal
              withCloseButton={false}
              opened={openedReport}
              onClose={() => setOpenedReport(false)}
              centered
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "10% 90%",
                  gridGap: "0px",
                }}
              >
                <div style={{ textAlign: "center" }}></div>
              </div>

              <div>
                <Text
                  align="left"
                  style={{ color: "#1F7A8C" }}
                  fw={500}
                  size="xl"
                  mb="lg"
                >
                  <Trans>Report Issue</Trans> :-
                </Text>
              </div>
              <RadioGroup value={value} onChange={handleChange}>
                {reportData?.map((item, index) => (
                  <div key={index} style={{ marginTop: "7px" }}>
                    <Radio
                      label={item?.masterName}
                      value={item?.masterName}
                      onChange={() => handleChange(item)}
                      checked={value === item?.masterName}
                    />
                  </div>
                ))}
              </RadioGroup>

              <div style={{ marginTop: "20px" }}>
                <Text
                  align="left"
                  style={{ color: "#1F7A8C" }}
                  fw={500}
                  size="md"
                >
                  <Trans>Remarks -</Trans>
                </Text>
                <TextInput
                  value={remarkValue}
                  onChange={(event) =>
                    setRemarkValue(event.currentTarget.value)
                  }
                  placeholder={t`Write report remarks`}
                />
              </div>

              <Stack align="center">
                <Group position="center" mt="md">
                  <Button
                    style={{ marginTop: "0px", borderColor: "#1F7A8C" }}
                    variant="outline"
                    onClick={closeReport}
                  >
                    <Text c="#1F7A8C" size="md" mb={0} mt={0} fw={500}>
                      <Trans>Cancel</Trans>
                    </Text>
                  </Button>
                  <Button
                    style={{ marginTop: "0px", borderColor: "#1F7A8C" }}
                    variant="outline"
                    onClick={handleReportJobAlert}
                  >
                    <Text c="#1F7A8C" size="md" mb={0} mt={0} fw={500}>
                      <Trans>Submit</Trans>
                    </Text>
                  </Button>
                </Group>
              </Stack>
            </Modal>

            {/* /////////////////report Alert/////////// */}
            <Modal
              withCloseButton={false}
              opened={openedReportAlert}
              onClose={() => setOpenedReportAlert(false)}
              centered
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "10% 90%",
                  gridGap: "0px",
                }}
              >
                <div style={{ textAlign: "center" }}></div>
                <Text
                  align="left"
                  style={{ color: "#1F7A8C" }}
                  fw={500}
                  size="lg"
                  mb="md"
                >
                  <Trans>Are you sure, you want to report this Job?</Trans>
                </Text>
              </div>

              <Stack align="center">
                <Group position="center" mt="md">
                  <Button
                    style={{ marginTop: "0px", borderColor: "#1F7A8C" }}
                    variant="outline"
                    onClick={closeReportAlert}
                  >
                    <Text c="#1F7A8C" size="md" mb={0} mt={0} fw={500}>
                      <Trans>No</Trans>
                    </Text>
                  </Button>
                  <Button
                    style={{ marginTop: "0px", borderColor: "#1F7A8C" }}
                    variant="outline"
                    onClick={handleReportJob}
                  >
                    <Text c="#1F7A8C" size="md" mb={0} mt={0} fw={500}>
                      <Trans>Yes</Trans>
                    </Text>
                  </Button>
                </Group>
              </Stack>
            </Modal>

            {/* //////////////////Empty Alert/////////////////////// */}

            <Modal
              withCloseButton={false}
              opened={openedReportAlertEmpty}
              onClose={() => setOpenedReportAlertEmpty(false)}
              centered
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "10% 90%",
                  gridGap: "0px",
                }}
              >
                <div style={{ textAlign: "center" }}></div>
                <Text
                  align="left"
                  style={{ color: "#1F7A8C" }}
                  fw={500}
                  size="xl"
                  mb="md"
                >
                  <Trans>Please Select/Write Reason</Trans>
                </Text>
              </div>

              <Stack align="center">
                <Group position="center" mt="md">
                  <Button
                    style={{ marginTop: "0px", borderColor: "#1F7A8C" }}
                    variant="outline"
                    onClick={closeReportAlertEmpty}
                  >
                    <Text c="#1F7A8C" size="md" mb={0} mt={0} fw={500}>
                      <Trans>OK</Trans>
                    </Text>
                  </Button>
                </Group>
              </Stack>
            </Modal>

            {/* ////////////////////////////////////// */}

            <div className={styles.jobDesContainer}>
              {showAlert && (
                // <Notification
                //   title="Please complete your profile before applying to this job"
                //   onClose={() => setShowAlert(false)}
                //   color='white'
                // style={{
                //   backgroundColor: "red",

                // }}
                //   Please complete your profile before applying to this job
                // />
                <Notification
                  // title="Resume Uploaded"
                  color="#eee"
                  onClose={() => setShowAlert(false)}
                  style={{
                    backgroundColor: "#FA5252",
                    color: "#eee",
                    position: "fixed",
                    width: "100%",
                    maxWidth: "600px",
                    zIndex: 9999,
                    top: 60,
                  }}
                >
                  <span style={{ color: "#eee", fontSize: "1rem" }}>
                    <Trans>
                      Please complete your profile before applying to this job.
                    </Trans>
                  </span>
                </Notification>
              )}

              {showAlertSuccess && (
                <Notification
                  color="#eee"
                  onClose={() => setShowAlertSuccess(false)}
                  style={{
                    backgroundColor: "#2aa63d",
                    color: "#eee",
                    position: "fixed",
                    width: "100%",
                    maxWidth: "600px",
                    zIndex: 9999,
                    top: 60,
                  }}
                >
                  <span style={{ color: "#eee", fontSize: "1rem" }}>
                    <Trans>
                      Congratulations! You have successfully submitted your
                      application.
                    </Trans>
                  </span>
                </Notification>
              )}

              {/*JOB DESCRIPTION*/}
              {jobData?.jobPostingTypeId == null && (<Container fluid bg="white" p="lg" style={{ borderBottom: "0.5px solid #D2D2D2" }}>
                <Group justify="space-between" align="center">
                  <Flex justify="start" style={{ minWidth: "13%" }}>
                    <IoArrowBack onClick={() => { navigate("/jobs?tab=all"); }} color='black' size={26} style={{ cursor: "pointer" }} />
                  </Flex>
                  <span style={{ fontWeight: "bold" }}><Trans>Job Description</Trans></span>
                  <Button onClick={shareToWhatsAppPrivate} variant="outline" radius="xl" size="xs" style={(theme) => ({
                    borderWidth: '2px',
                    padding: "5px 10px",
                    borderColor: "#1F7A8C"
                  })}>
                    <Flex style={{ gap: "6px" }}>
                      <CiShare2 style={{ color: "#1F7A8C" }} strokeWidth="2" />
                      <span style={{ fontWeight: "bold", color: "#1F7A8C" }}><Trans>Share</Trans></span>
                    </Flex></Button>
                </Group>
              </Container>)}
              {jobData?.jobPostingTypeId == 9732 && (<Container fluid bg="white" p="lg" style={{ borderBottom: "0.5px solid #D2D2D2" }}>
                <Group justify="space-between" align="center">
                  <Flex justify="start" style={{ minWidth: "13%" }}>
                    <IoArrowBack onClick={() => { navigate("/jobs?tab=walkin"); }} color='black' size={26} style={{ cursor: "pointer" }} />
                  </Flex>
                  <span style={{ fontWeight: "bold" }}><Trans>Job Description</Trans></span>
                  <Button onClick={shareToWhatsAppPrivate} variant="outline" color="black" radius="xl" size="xs" style={(theme) => ({

                    borderWidth: '2px',
                    padding: "5px 10px",
                    borderColor: "#034078"
                  })}>
                    <Flex style={{ gap: "6px" }}>
                      <CiShare2 style={{ color: "#034078" }} strokeWidth="2" />
                      <span style={{ fontWeight: "bold", color: "#034078" }}><Trans>Share</Trans></span>
                    </Flex></Button>
                </Group>
              </Container>)}
              {jobData?.jobPostingTypeId == 119 && (<Container fluid bg="white" p="lg" style={{ borderBottom: "0.5px solid #D2D2D2" }}>
                <Group justify="space-between" align="center">
                  <Flex justify="start" style={{ minWidth: "13%" }}>
                    <IoArrowBack onClick={() => { navigate("/jobs?tab=govt"); }} color='black' size={26} style={{ cursor: "pointer" }} />
                  </Flex>
                  <span style={{ fontWeight: "bold" }}><Trans>Job Description</Trans></span>
                  <Button onClick={shareToWhatsAppPrivate} variant="outline" color="black" radius="xl" size="xs" style={(theme) => ({

                    borderWidth: '2px',
                    padding: "5px 10px",
                    borderColor: "#38A78A"
                  })}>
                    <Flex style={{ gap: "6px" }}>
                      <CiShare2 style={{ color: "#38A78A" }} strokeWidth="2" />
                      <span style={{ fontWeight: "bold", color: "#38A78A" }}><Trans>Share</Trans></span>
                    </Flex></Button>
                </Group>
              </Container>)}

              <div style={{ padding: "10px 10px", backgroundColor: "white" }}>
                {jobData?.jobPostingTypeId == null && (
                  <>
                    {/*JOB TITLE*/}
                    <div>
                      <Card
                        style={{ minWidth: "350px", marginBottom: "4px", padding: "12px 12px 0px 12px" }}
                      >

                        <Flex direction="column" gap="md">

                          <Group fluid justify="space-between" align="flex-start">
                            <Flex direction="column" style={{ maxWidth: "75%" }}>
                              <span
                                style={{ fontWeight: "bold", fontSize: "24px" }}
                              >
                                {jobData?.jobTitle}
                              </span>
                              <span
                                style={{
                                  fontWeight: 600,
                                  color: "#808080",
                                }}
                              >
                                {jobData?.companyName}
                              </span>
                            </Flex>
                            <Flex>
                              {jobData?.logoVirtualPath ? (
                                <img
                                  src={jobData?.logoVirtualPath}
                                  alt="Company Logo"
                                  style={{ width: "55px", height: "55px" }}
                                />
                              ) : (
                                jobData?.employer?.logoVirtualPath && (
                                  <img
                                    src={jobData?.employer?.logoVirtualPath}
                                    alt="Company Logo"
                                    style={{ width: "55px", height: "55px" }}
                                  />
                                )
                              )}
                            </Flex>
                          </Group>
                          <Flex direction="column" gap="sm" ml={"5px"}>
                            <Flex align="flex-start">
                              <FaRupeeSign style={{ marginTop: "7px", fontSize: "12px", marginLeft: "2px" }} />

                              <span style={{ fontWeight: "600", marginLeft: "2px" }}><Trans>Salary</Trans>:</span>
                              &nbsp;
                              {jobData?.salary?.masterName}
                            </Flex>
                            <Flex align="flex-start">
                              <MdLocationOn style={{ marginTop: "6px", fontSize: "14px", }} />

                              <span style={{ fontWeight: "600", marginLeft: "2px" }}><Trans>Location</Trans>:</span>
                              &nbsp;
                              {jobData?.location?.masterName}
                              {jobData?.location?.parentModel?.masterName
                                ? `(${jobData?.location?.parentModel?.masterName})`
                                : ""}
                            </Flex>
                          </Flex>
                        </Flex>
                        <Flex gap="sm" style={{ padding: "14px 4px" }}>
                          <Badge color="green" variant="light" size="md" radius="sm" p="sm">
                            <span style={{ textTransform: "none", fontWeight: 600 }}>
                              {jobData?.numberOfOpenings} <Trans>Openings</Trans>
                            </span>
                          </Badge>
                          <Badge color="blue" variant="light" size="md" radius="sm" p="sm">
                            <span style={{ textTransform: "none", fontWeight: 600 }}>
                              <Trans>Type of work</Trans>: {jobData?.typeOfWork?.masterName}
                            </span>
                          </Badge>
                        </Flex>
                      </Card>
                    </div>

                    <Divider my="sm" variant="dashed" />

                    <Card radius="lg" p="xs">
                      <Flex direction="column" gap="md">
                        <span style={{ fontWeight: 'bold', marginLeft: "10px" }}>
                          <Text style={{ fontWeight: 'bold' }}><Trans>Qualifications</Trans></Text>
                        </span>
                        <Flex gap="sm" ml={"5px"}>
                          <Badge variant="outline" color="gray" size="lg" p="sm" style={{ color: "#3490e6" }}><span style={{ textTransform: "none", fontWeight: 600 }}><Trans>Experience</Trans> :{" "}
                            {jobData?.experience?.masterName}</span></Badge>
                          <Badge variant="outline" color="gray" size="lg" p="sm" style={{ color: "#3490e6" }}><span style={{ textTransform: "none", fontWeight: 600 }}><Trans>Education</Trans> :{" "}
                            {jobData?.education?.masterName}</span></Badge>
                        </Flex>
                      </Flex>
                    </Card>



                    {/*Job Details*/}

                    <Card padding="lg" radius="lg" shadow="sm" style={{
                      marginTop: "12px", marginBottom: "12px", border: "0.5px solid #D2D2D2",
                      boxShadow: "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)"
                    }}>
                      <Flex direction="column" gap="md">
                        <span style={{ fontWeight: 'bold' }}>
                          <Text style={{ fontWeight: 'bold' }}><Trans>Job Details</Trans></Text>
                        </span>
                        <Flex direction="column" gap="sm">
                          <span>
                            <Trans>Trade/Job Category</Trans> :   {jobData?.trade?.masterName}
                          </span>
                          <span>
                            <Trans>Job Shift</Trans>: {jobData?.jobShift?.masterName}
                          </span>
                          <span>
                            <Trans>Job Timing</Trans> :   {jobData?.jobTiming?.masterName}
                          </span>
                          <span>
                            <Trans>Job Posted Date </Trans> : {jobdate}
                          </span>
                          {/* <span>
                      <Trans>Bike/Driving License </Trans> : {jobData?.bikeAndDl}
                      </span> */}
                        </Flex>

                        <Flex gap="sm" ml={"5px"}>
                          <Badge variant="outline" color="gray" size="lg" p="sm" style={{ color: "#3490e6" }}><span style={{ textTransform: "none", fontWeight: 600 }}>Bike/DL :{" "}
                            {jobData?.bikeAndDl}</span></Badge>
                          {passport && (
                            <>
                              {passport == true ? (
                                <span>
                                  <Badge variant="outline" color="gray" size="lg" p="sm" style={{ color: "#3490e6" }}><span style={{ textTransform: "none", fontWeight: 600 }}>Passport :{" "}
                                    Yes</span></Badge>
                                </span>
                              ) : (
                                <>
                                  {passport == false && (
                                    <span>
                                      <Badge variant="outline" color="gray" size="lg" p="sm" style={{ color: "#3490e6" }}><span style={{ textTransform: "none", fontWeight: 600 }}>Passport :{" "}
                                        No</span></Badge>
                                    </span>
                                  )}
                                </>

                              )}
                            </>
                          )}
                        </Flex>

                      </Flex>
                    </Card>




                    {/*Small Components*/}

                    {/* <Flex 
                    direction="row"
                    justify="center"
                    style={{marginTop:"12px",marginBottom:"12px"}}
                    >
                    <Container size="responsive"
                    style={{backgroundColor:"#4282E3",color:"white", borderRadius: "5px",textAlign:"center",height:"30px", minWidth:"160px" ,boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.05)"}}>
      
                      <span style={{fontSize:"14px" ,fontWeight:500}}>
                      <Trans>Education</Trans> :  {jobData?.education?.masterName}
                      </span>
                      </Container>
                    <Container size="responsive"
                    style={{backgroundColor:"#4282E3",color:"white", borderRadius: "5px", textAlign:"center",height:"30px" , minWidth:"160px",maxWidth:"200px",boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.05)"}}>
                      <span style={{fontSize:"14px" ,fontWeight:500}}>
                      <Trans>Experience</Trans>: {jobData?.experience?.masterName}
                      </span>
                    </Container>
                    </Flex> */}

                    {/*Perks & Benefits*/}
                    {jobData?.jobDescription && (
                      <>
                        {Object.keys(jobData?.perksbenefits).length > 0 && (
                          <Card padding="lg" radius="md" shadow="sm" style={{
                            marginTop: "12px", backgroundColor: "#DCEDF9", marginBottom: "12px", border: "0.5px solid #D2D2D2",
                            boxShadow: "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)"
                          }}>
                            <Flex direction="column" gap="md">
                              <span style={{ fontWeight: 'bold' }}>
                                <Trans>Perks & Benefits</Trans> :
                              </span>

                              <Flex justify="space-between" align="flex-start" gap="md" wrap="wrap" style={{ width: "85%" }}>
                                {jobData?.perksbenefits?.map((item, key) => (

                                  <Flex key={key} align="start" justify="flex-start"  >

                                    <FaCheck size={21} style={{ marginTop: "2px", backgroundColor: "#ebfceb", color: "#52c672", padding: "4px", borderRadius: "50%" }} />


                                    &nbsp;
                                    <Text>
                                      {item.masterName}
                                    </Text>
                                  </Flex>

                                ))}
                              </Flex>
                            </Flex>
                          </Card>
                        )}
                      </>
                    )}

                    {/*Job Description*/}

                    {jobData?.jobDescription && (
                      <div>
                        <Card padding="lg" radius="md" shadow="sm" style={{
                          marginTop: "12px", marginBottom: "12px", border: "0.5px solid #D2D2D2",
                          boxShadow: "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)"
                        }}>
                          <Flex direction="column">
                            <span style={{ fontWeight: "bold" }}><Trans>Job Description</Trans></span>
                            <Spoiler maxHeight={65} showLabel="Read more" hideLabel="Read less" styles={(theme) => ({
                              control: {
                                right: 0,
                                insetInlineStart: "initial",
                                color: "green",
                                fontWeight: 600,
                              },
                            })} >
                              {parse(jobData?.jobDescription)}
                            </Spoiler>
                          </Flex>
                        </Card>
                      </div>
                    )}

                    {/*Job Disclaimer*/}
                    <div>
                      <Card padding="lg" radius="md" shadow="sm" style={{ marginTop: "12px", marginBottom: "12px", border: "0.5px solid #D2D2D2", color: "#444444" }}>
                        <Flex direction="column" gap="sm">
                          <span style={{ fontWeight: "bold", color: "black" }}><Trans>Disclaimer</Trans></span>
                          <Flex direction="column" gap="sm">
                            <Trans>There are no charges to search and apply Job</Trans>
                            <Flex justify="flex-start">
                              <Button
                                style={{ width: "35%" }}
                                variant="outline"
                                color="red"
                                onClick={reportbtn}
                              >
                                <Flex align="flex-start">
                                  <MdReport
                                    size={16}
                                    style={{
                                      color: "red",
                                      marginRight: "5px",
                                      textAlign: "center",
                                    }}
                                  />{" "}
                                  <span style={{ fontWeight: "bold" }}><Trans>Report</Trans></span>
                                </Flex>
                              </Button>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Card>
                    </div>






                    {/* <label>            
              <strong> {jobData?.jobTitle}</strong>
            </label>
            <br />

            <label style={{ lineHeight: "2rem" }}>
              {jobData?.companyName}
            </label>
            <br />
            <label style={{ lineHeight: "2rem" }}>
              <BsFillBagFill />
              &nbsp;
              <Trans>Openings</Trans> : {jobData?.numberOfOpenings}
            </label>
            <br />
            <label style={{ lineHeight: "2rem" }}>
              <FaTradeFederation />
              &nbsp;
              <Trans>Trade/Job Category</Trans> :{" "}
              {jobData?.trade?.masterName}
            </label>
            <br />
            <label style={{ lineHeight: "2rem" }}>
              <CiLocationOn />
              &nbsp;
              <Trans>Location</Trans> : {jobData?.location?.masterName}{" "}
              {jobData?.location?.parentModel?.masterName
                ? `(${jobData?.location?.parentModel?.masterName})`
                : ""}
            </label>
            <br />
            <label style={{ lineHeight: "2rem" }}>
              <MdOutlinePeopleOutline />
              &nbsp;
              <Trans>Experience</Trans> :{" "}
              {jobData?.experience?.masterName}
            </label> 


                    <label style={{ lineHeight: "2rem" }}>
                      <FaGraduationCap />
                      &nbsp;
                      <Trans>Education</Trans> :{" "}
                      {jobData?.education?.masterName}
                    </label>
                    <br />
                    {jobData?.jobPostingTypeId == 9732 && (
                      <>
                        <label style={{ lineHeight: "2rem" }}>
                          <CiLocationOn />
                          &nbsp;
                          <Trans>Interview Location</Trans> :{" "}
                          {
                            jobData?.walkInInterviewDescription
                              ?.interviewLocation
                          }
                        </label>
                        <br />
                        <label style={{ lineHeight: "2rem" }}>
                          <SlCalender />
                          &nbsp;
                          <Trans>Interview Date</Trans> :{" "}
                          {jobData?.walkInInterviewDescription?.interviewDate}
                        </label>
                        <br />
                        <label style={{ lineHeight: "2rem" }}>
                          <IoTime />
                          &nbsp;
                          <Trans>Interview Time</Trans> :{" "}
                          {jobData?.walkInInterviewDescription?.interviewTime}
                        </label>
                        <br />
                      </>
                    )}
                    <label style={{ lineHeight: "2rem" }}>
                      <FaWallet />
                      &nbsp;
                      <Trans>Salary</Trans> : {jobData?.salary?.masterName}
                    </label>
                    <br />
                    <label style={{ lineHeight: "2rem" }}>
                      <GiNotebook />
                      &nbsp;
                      <Trans> Type of work</Trans> :{" "}
                      {jobData?.typeOfWork?.masterName}
                    </label>
                    <br />
                    <label style={{ lineHeight: "2rem" }}>
                      <MdOutlinePedalBike />
                      &nbsp;
                      <Trans>Bike/Driving License </Trans> :{" "}
                      {jobData?.bikeAndDl}
                    </label>
                    <br />

                    {jobData?.jobPostingTypeId == null && (
                      <>
                        <label style={{ lineHeight: "2rem" }}>
                          <CiClock2 />
                          &nbsp;
                          <Trans>Job Timing</Trans> :{" "}
                          {jobData?.jobTiming?.masterName}
                        </label>
                        <br />

                        <label style={{ lineHeight: "2rem" }}>
                          <RxLapTimer />
                          &nbsp;
                          <Trans>Job Shift</Trans> :{" "}
                          {jobData?.jobShift?.masterName}
                        </label>
                        <br />

                        <label style={{ lineHeight: "2rem" }}>
                          <RxLapTimer />
                          &nbsp;
                          <Trans>Perks & Benefits</Trans> :
                          {jobData?.perksbenefits?.map((item, key) => (
                            <div key={key}>
                              <Text>{item.masterName}</Text>
                            </div>
                          ))}
                        </label>
                        <br />
                      </>
                    )}

                    <label style={{ lineHeight: "2rem" }}>
                      <SlCalender />
                      &nbsp;
                      <Trans>Job Posted Date </Trans> : {jobdate}
                    </label>
                    <br />

                    {jobData?.jobDescription && (
                      <label style={{ lineHeight: "2rem" }}>
                        <SlCalender />
                        &nbsp;
                        <Trans>Job Description</Trans> :{" "}
                        {parse(jobData?.jobDescription)}
                      </label>
                    )}
                    */}
                  </>
                )}
                {jobData?.jobPostingTypeId == 9732 && (
                  <>
                    {/*JOB TITLE*/}
                    <div>
                      <Card
                        style={{ minWidth: "350px", marginBottom: "4px", padding: "12px 12px 0px 12px" }}
                      >

                        <Flex direction="column" gap="md">

                          <Group fluid justify="space-between" align="flex-start">
                            <Flex direction="column" style={{ maxWidth: "75%" }}>
                              <span
                                style={{ fontWeight: "bold", fontSize: "24px" }}
                              >
                                {jobData?.jobTitle}
                              </span>
                              <span
                                style={{
                                  fontWeight: 600,
                                  color: "#808080",
                                }}
                              >
                                {jobData?.companyName}
                              </span>
                            </Flex>
                            <Flex>
                              {jobData?.logoVirtualPath ? (
                                <img
                                  src={jobData?.logoVirtualPath}
                                  alt="Company Logo"
                                  style={{ width: "55px", height: "55px" }}
                                />
                              ) : (
                                jobData?.employer?.logoVirtualPath && (
                                  <img
                                    src={jobData?.employer?.logoVirtualPath}
                                    alt="Company Logo"
                                    style={{ width: "55px", height: "55px" }}
                                  />
                                )
                              )}
                            </Flex>
                          </Group>
                          <Flex direction="column" gap="sm" ml={"5px"}>
                            <Flex align="flex-start">
                              <FaRupeeSign style={{ marginTop: "7px", fontSize: "12px", marginLeft: "2px" }} />

                              <span style={{ fontWeight: "600", marginLeft: "2px" }}><Trans>Salary</Trans>:</span>
                              &nbsp;
                              {jobData?.salary?.masterName}
                            </Flex>
                            <Flex align="flex-start">
                              <MdLocationOn style={{ marginTop: "6px", fontSize: "14px" }} />

                              <span style={{ fontWeight: "600", marginLeft: "2px" }}><Trans>Location</Trans>:</span>
                              &nbsp;
                              {jobData?.location?.masterName}
                              {jobData?.location?.parentModel?.masterName
                                ? `(${jobData?.location?.parentModel?.masterName})`
                                : ""}
                            </Flex>
                          </Flex>


                        </Flex>
                        <Flex gap="sm" style={{ padding: "14px 4px" }}>
                          <Badge color="green" variant="light" size="md" radius="sm" p="sm">
                            <span style={{ textTransform: "none", fontWeight: 600 }}>
                              {jobData?.numberOfOpenings} <Trans>Openings</Trans>
                            </span>
                          </Badge>
                          <Badge color="blue" variant="light" size="md" radius="sm" p="sm">
                            <span style={{ textTransform: "none", fontWeight: 600 }}>
                              <Trans>Type of work</Trans>: {jobData?.typeOfWork?.masterName}
                            </span>
                          </Badge>
                        </Flex>
                      </Card>
                    </div>

                    <Divider my="sm" variant="dashed" />

                    <Card radius="lg" p="xs">
                      <Flex direction="column" gap="md">
                        <span style={{ fontWeight: 'bold', marginLeft: "10px" }}>
                          <Text style={{ fontWeight: 'bold' }}><Trans>Qualifications</Trans></Text>
                        </span>
                        <Flex gap="sm" ml={"5px"}>
                          <Badge variant="outline" color="gray" size="lg" p="sm" style={{ color: "#3490e6" }}><span style={{ textTransform: "none", fontWeight: 600 }}><Trans>Experience</Trans> :{" "}
                            {jobData?.experience?.masterName}</span></Badge>
                          <Badge variant="outline" color="gray" size="lg" p="sm" style={{ color: "#3490e6" }}><span style={{ textTransform: "none", fontWeight: 600 }}><Trans>Education</Trans> :{" "}
                            {jobData?.education?.masterName}</span></Badge>
                        </Flex>
                      </Flex>
                    </Card>



                    {/* JOB DETAILS */}
                    <Card padding="lg" radius="lg" shadow="sm" style={{
                      marginTop: "12px", marginBottom: "12px", border: "0.5px solid #D2D2D2",
                      boxShadow: "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)"
                    }}>
                      <Flex direction="column" gap="md">
                        <span style={{ fontWeight: 'bold' }}>
                          <Text style={{ fontWeight: 'bold' }}>Job Details</Text>
                        </span>
                        <Flex direction="column" gap="sm">
                          <span>
                            <Trans>Trade/Job Category</Trans> :   {jobData?.trade?.masterName}
                          </span>
                          <span>
                            <Trans>Job Posted Date </Trans> : {jobdate}
                          </span>
                          <span>
                            <Trans>Bike/Driving License </Trans> : {jobData?.bikeAndDl}
                          </span>
                        </Flex>
                      </Flex>
                    </Card>

                    {/* INTERVIEW DETAILS */}
                    <Card padding="lg" radius="lg" shadow="sm" style={{
                      marginTop: "12px", marginBottom: "12px", border: "0.5px solid #D2D2D2",
                      boxShadow: "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)"
                    }}>
                      <Flex direction="column" gap="md">
                        <span style={{ fontWeight: 'bold' }}>
                          <Text style={{ fontWeight: 'bold' }}>Interview Details</Text>
                        </span>
                        <Flex direction="column" gap="sm">
                          <Flex align="flex-start">
                            <MdLocationOn style={{ marginTop: "5px", minWidth: "16px" }} />
                            &nbsp;
                            <div><Trans>Interview Location</Trans> :{" "}
                              {
                                jobData?.walkInInterviewDescription
                                  ?.interviewLocation
                              }</div>
                          </Flex>
                          <Flex align="flex-start">
                            <FaCalendarAlt style={{ marginTop: "5px" }} />
                            &nbsp;
                            <div><Trans>Interview Date</Trans> :{" "}
                              {jobData?.walkInInterviewDescription?.interviewDate}</div>
                          </Flex>
                          <Flex align="flex-start" >
                            <IoTime style={{ marginTop: "5px" }} />
                            &nbsp;
                            <div><Trans>Interview Time</Trans> :{" "}
                              {jobData?.walkInInterviewDescription?.interviewTime}</div>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Card>

                    {/*Job Description*/}

                    {jobData?.jobDescription && (
                      <div>
                        <Card padding="lg" radius="lg" shadow="sm" style={{
                          marginTop: "12px", marginBottom: "12px", border: "0.5px solid #D2D2D2",
                          boxShadow: "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)"
                        }}>
                          <Flex direction="column">
                            <span style={{ fontWeight: "bold" }}><Trans>Job Description</Trans></span>
                            <Spoiler maxHeight={65} showLabel="Read more" hideLabel="Read less" styles={(theme) => ({
                              control: {
                                right: 0,
                                insetInlineStart: "initial",
                                color: "green",
                                fontWeight: 600,
                              },
                            })} >
                              {parse(jobData?.jobDescription)}
                            </Spoiler>
                          </Flex>
                        </Card>
                      </div>
                    )}
                  </>
                )}
                {jobData?.jobPostingTypeId == 119 && (
                  <>

                    {/*JOB TITLE*/}
                    <div>
                      <Card
                        style={{ minWidth: "350px", marginBottom: "4px", padding: "12px 12px 0px 12px" }}
                      >

                        <Flex direction="column" gap="md">

                          <Group fluid justify="space-between" align="flex-start">
                            <Flex direction="column" style={{ maxWidth: "75%" }}>
                              <span
                                style={{ fontWeight: "bold", fontSize: "24px" }}
                              >
                                {jobData?.jobTitle}
                              </span>
                              <span
                                style={{
                                  fontWeight: 600,
                                  color: "#808080",
                                }}
                              >
                                {jobData?.companyName}
                              </span>
                            </Flex>
                            <Flex>
                              {jobData?.logoVirtualPath ? (
                                <img
                                  src={jobData?.logoVirtualPath}
                                  alt="Company Logo"
                                  style={{ width: "55px", height: "55px" }}
                                />
                              ) : (
                                jobData?.employer?.logoVirtualPath && (
                                  <img
                                    src={jobData?.employer?.logoVirtualPath}
                                    alt="Company Logo"
                                    style={{ width: "55px", height: "55px" }}
                                  />
                                )
                              )}
                            </Flex>
                          </Group>
                          <Flex direction="column" gap="sm" ml={"5px"}>
                            <Flex align="flex-start">
                              <FaRupeeSign style={{ marginTop: "7px", fontSize: "12px", marginLeft: "2px" }} />

                              <span style={{ fontWeight: "600", marginLeft: "2px" }}><Trans>Salary</Trans>:</span>
                              &nbsp;
                              {jobData?.salary?.masterName}
                            </Flex>
                            {/* <Flex align="flex-start">
                              <MdLocationOn   style={{marginTop:"5px"}}/>
                              &nbsp;
                              <span style={{fontWeight:"600"}}><Trans>Location</Trans>:</span>
                              &nbsp;
                              {jobData?.location?.masterName}
                              {jobData?.location?.parentModel?.masterName
                                ? `(${jobData?.location?.parentModel?.masterName})`
                                : ""}
                            </Flex> */}
                          </Flex>


                        </Flex>
                        <Flex gap="sm" style={{ padding: "14px 4px" }}>
                          <Badge color="green" variant="light" size="md" radius="sm" p="sm">
                            <span style={{ textTransform: "none", fontWeight: 600 }}>
                              {jobData?.numberOfOpenings} <Trans>Openings</Trans>
                            </span>
                          </Badge>
                          <Badge color="blue" variant="light" size="md" radius="sm" p="sm">
                            <span style={{ textTransform: "none", fontWeight: 600 }}>
                              <Trans>Type of work</Trans>: {jobData?.typeOfWork?.masterName}
                            </span>
                          </Badge>
                        </Flex>
                      </Card>
                    </div>

                    <Divider my="sm" variant="dashed" />

                    <Card radius="lg" p="xs">
                      <Flex direction="column" gap="md">
                        <span style={{ fontWeight: 'bold', marginLeft: "10px" }}>
                          <Text style={{ fontWeight: 'bold' }}><Trans>Qualifications</Trans></Text>
                        </span>
                        <Flex gap="sm" ml={"5px"}>
                          <Badge variant="outline" color="gray" size="lg" p="sm" style={{ color: "#3490e6" }}><span style={{ textTransform: "none", fontWeight: 600 }}><Trans>Experience</Trans> :{" "}
                            {jobData?.experience?.masterName}</span></Badge>
                          <Badge variant="outline" color="gray" size="lg" p="sm" style={{ color: "#3490e6" }}><span style={{ textTransform: "none", fontWeight: 600 }}><Trans>Education</Trans> :{" "}
                            {jobData?.education?.masterName}</span></Badge>
                        </Flex>
                      </Flex>
                    </Card>


                    {/*Job Details*/}

                    <Card padding="lg" radius="lg" shadow="sm" style={{
                      marginTop: "12px", marginBottom: "12px", border: "0.5px solid #D2D2D2",
                      boxShadow: "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)"
                    }}>
                      <Flex direction="column" gap="md">
                        <span style={{ fontWeight: 'bold' }}>
                          <Text style={{ fontWeight: 'bold' }}>Job Details</Text>
                        </span>
                        <Flex direction="column" gap="sm">
                          <span>
                            <Trans>Trade/Job Category</Trans> :   {jobData?.trade?.masterName}
                          </span>
                          {/* <span>
                      <Trans>Education</Trans>:  {jobData?.education?.masterName}
                      </span> */}
                          {/* <span>
                      <Trans>Type</Trans>: {jobData?.typeOfWork?.masterName}
                      </span> */}
                          <span>
                            <Trans>Job Posted Date </Trans> : {jobdate}
                          </span>
                          {
                            jobData?.bikeAndDl == "Yes" && <span>
                              <Trans>Bike/Driving License </Trans> : {jobData?.bikeAndDl}
                            </span>
                          }
                        </Flex>
                      </Flex>
                    </Card>

                    {/*Small Components*/}

                    {/* <Flex 
                    direction="row"
                    justify="center"
                    style={{marginTop:"12px",marginBottom:"12px"}}
                    >
                    <Container size="responsive"
                    style={{backgroundColor:"#B4DAFD", borderRadius: "5px",textAlign:"center",height:"30px", minWidth:"160px" ,boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.05)"}}>
                      <span style={{fontSize:"14px" ,fontWeight:500}}>
                      <Trans>Education</Trans>:  {jobData?.education?.masterName}
                      </span>
                      </Container>
                    <Container size="responsive"
                    style={{backgroundColor:"#B4DAFD", borderRadius: "5px", textAlign:"center",height:"30px" , minWidth:"160px",maxWidth:"200px",boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.05)"}}>
                      <span style={{fontSize:"14px" ,fontWeight:500}}>
                    <Trans>Type</Trans>: {jobData?.typeOfWork?.masterName}
                      </span>
                    </Container>
                    </Flex> */}

                    {/*Job Description*/}

                    {jobData?.jobDescription && (
                      <div>
                        <Card padding="lg" radius="lg" shadow="sm" style={{
                          marginTop: "12px", marginBottom: "12px", border: "0.5px solid #D2D2D2",
                          boxShadow: "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)"
                        }}>
                          <Flex direction="column">
                            <span style={{ fontWeight: "bold" }}><Trans>Job Description</Trans></span>
                            <Spoiler maxHeight={65} showLabel="Read more" hideLabel="Read less" styles={(theme) => ({
                              control: {
                                right: 0,
                                insetInlineStart: "initial",
                                color: "green",
                                fontWeight: 600,
                              },
                            })} >
                              {parse(jobData?.jobDescription)}
                            </Spoiler>
                          </Flex>
                        </Card>
                      </div>
                    )}




                    {/* 
                    <div style={{ textAlign: "center", fontSize: "20px" }}>
                      <Trans>Job Description</Trans>
                    </div>

                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        margin: "5px",
                      }}
                    >
                      {jobData?.logoVirtualPath ? (
                        <img
                          src={jobData?.logoVirtualPath}
                          alt="Company Logo"
                          style={{ width: "90px", height: "90px" }}
                        />
                      ) : (
                        jobData?.employer?.logoVirtualPath && (
                          <img
                            src={jobData?.employer?.logoVirtualPath}
                            alt="Company Logo"
                            style={{ width: "90px", height: "90px" }}
                          />
                        )
                      )}
                    </div> 

                    <label>
                      {<MdPerson > }
                      <strong> {jobData?.jobTitle}</strong>
                    </label>
                    <br />
                    
                    <label style={{ lineHeight: "2rem" }}>
                      {jobData?.companyName}
                    </label>
                    
                    <br />
                    <label style={{ lineHeight: "2rem" }}>
                      <BsFillBagFill />
                      &nbsp;
                      <Trans>Openings</Trans> : {jobData?.numberOfOpenings}
                    </label>
                    <br />
                    <label style={{ lineHeight: "2rem" }}>
                      <FaTradeFederation />
                      &nbsp;
                      <Trans>Trade/Job Category</Trans> :{" "}
                      {jobData?.trade?.masterName}
                    </label>
                    <br />
                    <label style={{ lineHeight: "2rem" }}>
                      <CiLocationOn />
                      &nbsp;
                      <Trans>Location</Trans> : {jobData?.location?.masterName}{" "}
                      {jobData?.location?.parentModel?.masterName
                        ? `(${jobData?.location?.parentModel?.masterName})`
                        : ""}
                    </label>
                    <br />
                    <label style={{ lineHeight: "2rem" }}>
                      <MdOutlinePeopleOutline />
                      &nbsp;
                      <Trans>Experience</Trans> :{" "}
                      {jobData?.experience?.masterName}
                    </label>
                    <br />
                    <label style={{ lineHeight: "2rem" }}>
                      <FaGraduationCap />
                      &nbsp;
                      <Trans>Education</Trans> :{" "}
                      {jobData?.education?.masterName}
                    </label>
                    <br />
                    {jobData?.jobPostingTypeId == 9732 && (
                      <>
                        <label style={{ lineHeight: "2rem" }}>
                          <CiLocationOn />
                          &nbsp;
                          <Trans>Interview Location</Trans> :{" "}
                          {
                            jobData?.walkInInterviewDescription
                              ?.interviewLocation
                          }
                        </label>
                        <br />
                        <label style={{ lineHeight: "2rem" }}>
                          <SlCalender />
                          &nbsp;
                          <Trans>Interview Date</Trans> :{" "}
                          {jobData?.walkInInterviewDescription?.interviewDate}
                        </label>
                        <br />
                        <label style={{ lineHeight: "2rem" }}>
                          <IoTime />
                          &nbsp;
                          <Trans>Interview Time</Trans> :{" "}
                          {jobData?.walkInInterviewDescription?.interviewTime}
                        </label>
                        <br />
                      </>
                    )}
                    <label style={{ lineHeight: "2rem" }}>
                      <FaWallet />
                      &nbsp;
                      <Trans>Salary</Trans> : {jobData?.salary?.masterName}
                    </label>
                    <br />
                    <label style={{ lineHeight: "2rem" }}>
                      <GiNotebook />
                      &nbsp;
                      <Trans> Type of work</Trans> :{" "}
                      {jobData?.typeOfWork?.masterName}
                    </label>
                    <br />
                    <label style={{ lineHeight: "2rem" }}>
                      <MdOutlinePedalBike />
                      &nbsp;
                      <Trans>Bike/Driving License </Trans> :{" "}
                      {jobData?.bikeAndDl}
                    </label>
                    <br />

                    {jobData?.jobPostingTypeId == null && (
                      <>
                        <label style={{ lineHeight: "2rem" }}>
                          <CiClock2 />
                          &nbsp;
                          <Trans>Job Timing</Trans> :{" "}
                          {jobData?.jobTiming?.masterName}
                        </label>
                        <br />

                        <label style={{ lineHeight: "2rem" }}>
                          <RxLapTimer />
                          &nbsp;
                          <Trans>Job Shift</Trans> :{" "}
                          {jobData?.jobShift?.masterName}
                        </label>
                        <br />

                        <label style={{ lineHeight: "2rem" }}>
                          <RxLapTimer />
                          &nbsp;
                          <Trans>Perks & Benefits</Trans> :
                          {jobData?.perksbenefits?.map((item, key) => (
                            <div key={key}>
                              <Text>{item.masterName}</Text>
                            </div>
                          ))}
                        </label>
                        <br />
                      </>
                    )}

                    <label style={{ lineHeight: "2rem" }}>
                      <SlCalender />
                      &nbsp;
                      <Trans>Job Posted Date </Trans> : {jobdate}
                    </label>
                    <br />

                    {jobData?.jobDescription && (
                      <label style={{ lineHeight: "2rem" }}>
                        <SlCalender />
                        &nbsp;
                        <Trans>Job Description</Trans> :{" "}
                        {parse(jobData?.jobDescription)}
                      </label>
                    )}
                      */}
                  </>
                )}

                {jobData?.jobPostingTypeId != null && (
                  <div style={{ marginTop: "100px" }}>
                    <h6>.</h6>
                  </div>
                )}

                {/* <br /> */}

                {jobData?.jobPostingTypeId == null && (
                  <>
                    {/*<div style={{ marginTop: "5px" }}>
                      <Text>
                        <Trans>Disclaimer</Trans>
                      </Text>
                      <Text size="sm" fw={500} style={{ marginTop: "5px" }}>
                        <Trans>
                          There are no charges to search and apply Job
                        </Trans>
                      </Text>
                      <div>
                        <Button
                          style={{ marginTop: "5px", borderColor: "#1F7A8C" }}
                          variant="outline"
                          onClick={reportbtn}
                        >
                          <Text c="#1F7A8C" size="md" mb={0} mt={0} fw={600}>
                            <MdReport
                              size={16}
                              style={{
                                color: "red",
                                marginRight: "5px",
                                textAlign: "center",
                              }}
                            />{" "}
                            <Trans>Report</Trans>
                          </Text>
                        </Button>
                      </div>
                      </div>*/}
                    {user && (
                      <div>
                        {/* <Divider my="sm" variant="dashed" /> */}
                        <div style={{ marginLeft: "20px" }}>
                          <h4>
                            <Trans>Similar Jobs</Trans>
                          </h4>
                        </div>
                        {loading ? (
                          <div
                            style={{
                              paddingBottom: "10px",
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr",
                              gridGap: "20px",
                            }}
                          >
                            <div>
                              <Skeleton height={50} circle mb="xl" />
                              <Skeleton height={8} radius="xl" />
                              <Skeleton height={8} mt={6} radius="xl" />
                              <Skeleton
                                height={8}
                                mt={6}
                                width="100%"
                                radius="xl"
                                style={{ marginBottom: "100px" }}
                              />
                            </div>
                            <div>
                              <Skeleton height={50} circle mb="xl" />
                              <Skeleton height={8} radius="xl" />
                              <Skeleton height={8} mt={6} radius="xl" />
                              <Skeleton
                                height={8}
                                mt={6}
                                width="100%"
                                radius="xl"
                                style={{ marginBottom: "100px" }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div onClick={scrollFun}>
                            <div className={classes.bestjobcardcontainer}>
                              {similarJobdata.length > 0 &&
                                similarJobdata.map((job) => {

                                  return (
                                    <BestJobCard
                                      id={job?.id}
                                      jobTitle={job?.jobTitle}
                                      trade={job?.trade?.masterName}
                                      companyName={job?.companyName}
                                      workType={job?.typeOfWork?.masterName}
                                      education={job?.education?.masterName}
                                      location={job?.location?.masterName}
                                      salary={job?.salary?.masterName}
                                      openings={job?.numberOfOpenings}
                                      jobDate={job?.createdAt}
                                      state={
                                        job?.location?.parentModel?.masterName
                                      }
                                      logo={
                                        job?.logoVirtualPath
                                          ? job?.logoVirtualPath
                                          : job?.employer?.logoVirtualPath
                                      }
                                    />
                                  );
                                })}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}

                {/* <br /> */}

                {jobData?.jobPostingTypeId == null && (
                  <Card padding="lg" radius="lg" shadow="sm" style={{
                    marginTop: "25px", marginBottom: "0px", border: "0.5px solid #D2D2D2",
                    boxShadow: "0 4px 6px rgba(173, 216, 230, 0.4), inset 0 1px 3px rgba(255, 255, 255, 0.6)"
                  }}>
                    <div style={{ marginTop: "0px" }}>
                      <div style={{ fontWeight: "bold", marginLeft: "2px" }}>
                        <Trans>About Company</Trans>
                      </div>
                      <div style={{ fontSize: "12px", color: "#808080", marginTop: "8px" }}>
                        <Spoiler maxHeight={40} showLabel="Read more" hideLabel="Read less" styles={(theme) => ({
                          control: {
                            right: 0,
                            insetInlineStart: "initial",
                            color: "green",
                            fontWeight: "600",
                            fontSize: "12px",
                          },
                        })} >
                          {/*jobData?.employer?.description*/}
                        </Spoiler>
                      </div>
                      <div style={{ fontWeight: "bold", marginTop: "12px", marginBottom: "4px" }}>
                        <Badge variant="light" color="blue" size="md" radius="sm">Company Info</Badge>
                      </div>
                      <Stack gap="xs" style={{ paddingTop: "8px", paddingLeft: "8px", fontSize: "13px" }}>
                        <Flex style={{ gap: "4px" }}>
                          <Flex align="flex-start" style={{ width: "40%", fontWeight: "600", }}><HiOutlineBuildingOffice2 size={18} style={{ marginRight: "2px" }} /><Trans>Company Name</Trans>:{" "}</Flex>
                          <span>{jobData?.employer?.organizationName}</span>
                        </Flex>
                        <Flex style={{ gap: "4px" }}>
                          <Flex align="flex-start" style={{ width: "40%", fontWeight: "600" }}><HiOutlineUserGroup size={16} style={{ strokeWidth: "2px", marginRight: "4px" }} /><Trans>Recruiter Type</Trans>:{" "}</Flex>
                          <span>{jobData?.employer?.employerType?.masterName}</span>
                        </Flex>
                        <Flex style={{ gap: "4px" }}>
                          <Flex align="flex-start" style={{ width: "40%", fontWeight: "600" }}><LuAtSign size={16} style={{ marginRight: "4px", marginTop: "1px" }} /><Trans>Email</Trans>:{" "}</Flex>
                          <span>{jobData?.employer?.email}</span>
                        </Flex>
                        <Flex style={{ gap: "4px" }}>
                          <Flex align="flex-start" style={{ width: "40%", fontWeight: "600" }}><MdOutlineLocationOn size={18} style={{ marginRight: "2px", paddingRight: "1px" }} /><Trans>Company Address</Trans>:{" "}</Flex>
                          <span>{jobData?.employer?.address}</span>
                        </Flex>
                      </Stack>
                      {/*<div style={{ paddingBottom: "20px" }}>
                    <div style={{ marginTop: "0px" }}>
                      <h4>
                        <Trans>About Company</Trans>
                      </h4>
                    </div>

                    {jobData?.employer && (
                      <Card withBorder radius="md" className={classes.card}>
                        <Group mt="md">
                          <div>
                            <div style={{ marginTop: "0px" }}>
                              <Text size="sm" mb={5} mt={0} fw={600}>
                                <Trans>Company Name</Trans> :{" "}
                                {jobData?.employer?.organizationName}
                              </Text>
                              <Text size="sm" mb={5} mt={5} fw={600}>
                                <Trans>Recruiter Type</Trans> :{" "}
                                {jobData?.employer?.employerType?.masterName}
                              </Text>
                              <Text size="sm" mb={5} mt={5} fw={600}>
                                <Trans>Email</Trans> :{" "}
                                {jobData?.employer?.email}
                              </Text>
                              <Text size="sm" mb={5} mt={5} fw={600}>
                                <Trans>Company Address</Trans> :{" "}
                                {jobData?.employer?.address}
                              </Text>
                              <Text size="sm" mb={5} mt={5} fw={600}>
                                <Trans>About Company</Trans> :{" "}
                                {jobData?.employer?.description}
                              </Text>
                            </div>
                          </div>
                        </Group>
                      </Card>
                    )}
                  </div>*/}
                    </div>
                  </Card>
                )}

                <br />

                {jobData?.jobPostingTypeId == null && (
                  <>
                    {user && (
                      <div>
                        <div style={{ marginLeft: "20px" }}>
                          <h4>
                            <Trans>More Jobs</Trans>
                          </h4>
                        </div>

                        {loadingMore ? (
                          <div
                            style={{
                              paddingBottom: "10px",
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr",
                              gridGap: "20px",
                            }}
                          >
                            <div>
                              <Skeleton height={50} circle mb="xl" />
                              <Skeleton height={8} radius="xl" />
                              <Skeleton height={8} mt={6} radius="xl" />
                              <Skeleton
                                height={8}
                                mt={6}
                                width="100%"
                                radius="xl"
                                style={{ marginBottom: "100px" }}
                              />
                            </div>
                            <div>
                              <Skeleton height={50} circle mb="xl" />
                              <Skeleton height={8} radius="xl" />
                              <Skeleton height={8} mt={6} radius="xl" />
                              <Skeleton
                                height={8}
                                mt={6}
                                width="100%"
                                radius="xl"
                                style={{ marginBottom: "100px" }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div onClick={scrollFun}>
                            <div className={classes.bestjobcardcontainer}>
                              {moreJobdata.length > 0 &&
                                moreJobdata.map((job) => {
                                  return (
                                    <BestJobCard
                                      id={job?.id}
                                      jobTitle={job?.jobTitle}
                                      trade={job?.trade?.masterName}
                                      companyName={job?.companyName}
                                      workType={job?.typeOfWork?.masterName}
                                      education={job?.education?.masterName}
                                      location={job?.location?.masterName}
                                      salary={job?.salary?.masterName}
                                      openings={job?.numberOfOpenings}
                                      jobDate={job?.createdAt}
                                      state={
                                        job?.location?.parentModel?.masterName
                                      }
                                      logo={
                                        job?.logoVirtualPath
                                          ? job?.logoVirtualPath
                                          : job?.employer?.logoVirtualPath
                                      }
                                    />
                                  );
                                })}
                            </div>
                            <div style={{ marginTop: "100px" }}>
                              <h6>.</h6>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            <div
              className={`${candidateid
                ? styles.buttonsectionwrapper
                : styles.buttonsectionwrapperpublic
                }`}
            >
              {user ? (
                <div>
                  {jobData?.phoneNoOption == "No" && (
                    <div>
                      {jobData?.jobPostingTypeId == null && (
                        <div
                          className={classes.buttonsection2}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {!applied && (
                            <Button
                              w="100%"
                              onClick={assessmentbtn}
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                                border: "none",
                                cursor: "pointer",
                                background: "#1F7A8C",
                                color: "#fff",
                                width: "100%",
                              }}
                            >
                              {" "}
                              <Trans>Apply Now</Trans>
                            </Button>
                          )}

                          {applied && (
                            <Button
                              w="100%"
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                                border: "none",
                                cursor: "not-allowed",
                                background: "gray",
                                color: "#fff",
                                width: "100%",
                              }}
                            >
                              {" "}
                              <Trans>Applied</Trans>
                            </Button>
                          )}
                          {/* {<div className={classes.sharesection}>
                            <Button
                              onClick={shareToWhatsAppPrivate}
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                                background: "white",
                                color: "#000",
                              }}
                              className={classes.sharebtn}
                            >
                              <CiShare2
                                style={{
                                  color: "green",
                                  fontWeight: "500",
                                  fontSize: "30px",
                                  marginRight: "10px",
                                }}
                              />
                              <Trans>Share</Trans>
                            </Button>
                          </div>} */}
                        </div>
                      )}
                    </div>
                  )}

                  {jobData?.phoneNoOption == "Yes" && (
                    <div>
                      {jobData?.jobPostingTypeId == null && (
                        <div
                          className={classes.buttonsection2}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {!applied && (
                            <Button
                              w="100%"
                              onClick={assessmentbtn}
                              style={{
                                marginLeft: "5px",
                                border: "none",
                                marginRight: "5px",
                                cursor: "pointer",
                                background: "#1F7A8C",
                                color: "#fff",
                                width: "100%",
                              }}
                            >
                              <Text c="white" size="md" mb={5} mt={5} fw={600}>
                                <Trans>Apply Now</Trans>
                              </Text>
                            </Button>
                          )}

                          {applied && (
                            <Button
                              w="100%"
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                                border: "none",
                                marginLeft: "5px",
                                cursor: "pointer",
                                background: "gray",
                                color: "#fff",
                                width: "100%",
                              }}
                            >
                              <Text c="white" size="md" mb={5} mt={5} fw={600}>
                                <Trans>Applied</Trans>
                              </Text>
                            </Button>
                          )}

                          {isWithinCallTime ? (
                            <Button
                              w="100%"
                              onClick={handleCallHR}
                              style={{
                                marginLeft: "5px",
                                border: "none",
                                marginRight: "5px",
                                cursor: "pointer",
                                background: "#2aa63d",
                                color: "#fff",
                                width: "100%",
                              }}
                            >
                              <Text c="white" size="md" mb={5} mt={5} fw={600}>
                                <Trans>Call HR</Trans>
                              </Text>
                            </Button>
                          ) : (
                            <Button
                              w="100%"
                              onClick={handleCallHRNew}
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                                border: "none",
                                cursor: "pointer",
                                background: "gray",
                                color: "#fff",
                                width: "100%",
                              }}
                            >
                              <Text c="white" size="md" mb={5} mt={5} fw={600}>
                                <Trans>Call HR</Trans>
                              </Text>
                            </Button>
                          )}

                          {/* {<div className={classes.sharesection}>
                            <Button
                              onClick={shareToWhatsAppPrivate}
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                                background: "white",
                                color: "#000",
                              }}
                              className={classes.sharebtn}
                            >
                              <CiShare2
                                style={{
                                  color: "green",
                                  fontWeight: "500",
                                  fontSize: "30px",
                                  marginRight: "10px",
                                }}
                              />
                              <Trans>Share</Trans>
                            </Button>
                          </div>} */}
                        </div>
                      )}
                    </div>
                  )}

                  {jobData?.jobPostingTypeId == 119 && (
                    <div
                      className={classes.buttonsection2}
                      style={{
                        display: "flex",
                        marginRight: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          marginLeft: "5px",
                          marginRight: "5px",
                          color: "#fff",
                          width: "100%",
                        }}
                      >
                        <a href={jobData?.jobThirdpartyApplyLink}>
                          <Button
                            w="100%"
                            onClick={handleGovtJobApply}
                            style={{
                              marginLeft: "5px",
                              marginRight: "5px",
                              border: "none",
                              cursor: "pointer",
                              background: "#38A78A",
                              color: "#fff",
                            }}
                          >
                            {" "}
                            <Trans>Visit Link</Trans>
                          </Button>
                        </a>
                      </div>
                      {/* {<div className={classes.sharesection}>
                        <Button
                          onClick={shareToWhatsAppGovt}
                          style={{
                            marginLeft: "5px",
                            marginRight: "5px",
                            background: "white",
                            color: "#000",
                          }}
                          className={classes.sharebtn}
                        >
                          <CiShare2
                            style={{
                              color: "green",
                              fontWeight: "500",
                              fontSize: "30px",
                              marginRight: "10px",
                            }}
                          />
                          <Trans>Share</Trans>
                        </Button>
                      </div>} */}
                    </div>
                  )}

                  {jobData?.jobPostingTypeId == 9732 && (
                    <div
                      className={classes.buttonsection2}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          marginLeft: "5px",
                          marginRight: "15px",
                          color: "#fff",
                          width: "100%",
                        }}
                      >
                        <a href={jobData?.jobThirdpartyApplyLink}>
                          <Button
                            w="100%"
                            onClick={handleWalkInJobApply}
                            style={{
                              marginLeft: "5px",
                              marginRight: "5px",
                              border: "none",
                              cursor: "pointer",
                              background: "#034078",
                              color: "#fff",
                              width: "100%",
                            }}
                          >
                            {" "}
                            <Trans>More Information</Trans>
                          </Button>
                        </a>
                      </div>
                      {/* <div className={classes.sharesection}>
                        <Button
                          onClick={shareToWhatsAppWalkIn}
                          style={{
                            marginLeft: "5px",
                            marginRight: "5px",
                            background: "white",
                            color: "#000",
                          }}
                          className={classes.sharebtn}
                        >
                          <CiShare2
                            style={{
                              color: "green",
                              fontWeight: "500",
                              fontSize: "30px",
                              marginRight: "10px",
                            }}
                          />
                          <Trans>Share</Trans>
                        </Button>
                      </div> */}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {jobData?.phoneNoOption == "No" && (
                    <div>
                      {jobData?.jobPostingTypeId == null && (
                        <div
                          className={classes.buttonsection2}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            w="100%"
                            onClick={handleRedirectLogin}
                            style={{
                              marginLeft: "5px",
                              marginRight: "5px",
                              border: "none",
                              cursor: "pointer",
                              background: "#1F7A8C",
                              color: "#fff",
                              width: "100%",
                            }}
                          >
                            {" "}
                            <Trans>Apply Now</Trans>
                          </Button>

                          {/* {<div className={classes.sharesection}>
                            <Button
                              onClick={shareToWhatsAppPrivate}
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                                background: "white",
                                color: "#000",
                              }}
                              className={classes.sharebtn}
                            >
                              <CiShare2
                                style={{
                                  color: "green",
                                  fontWeight: "500",
                                  fontSize: "30px",
                                  marginRight: "10px",
                                }}
                              />
                              <Trans>Share</Trans>
                            </Button>
                          </div>} */}
                        </div>
                      )}
                    </div>
                  )}

                  {jobData?.phoneNoOption == "Yes" && (
                    <div>
                      {jobData?.jobPostingTypeId == null && (
                        <div
                          className={classes.buttonsection2}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            w="100%"
                            onClick={handleRedirectLogin}
                            style={{
                              marginLeft: "5px",
                              border: "none",
                              marginRight: "5px",
                              cursor: "pointer",
                              background: "#1F7A8C",
                              color: "#fff",
                              width: "100%",
                            }}
                          >
                            <Text c="white" size="md" mb={5} mt={5} fw={600}>
                              <Trans>Apply Now</Trans>
                            </Text>
                          </Button>

                          <Button
                            w="100%"
                            onClick={handleRedirectLogin}
                            style={{
                              marginLeft: "5px",
                              marginRight: "5px",
                              border: "none",
                              cursor: "pointer",
                              background: "#2aa63d",
                              color: "#fff",
                              width: "100%",
                            }}
                          >
                            <Text c="white" size="md" mb={5} mt={5} fw={600}>
                              <Trans>Call HR</Trans>
                            </Text>
                          </Button>

                          {/* {<div className={classes.sharesection}>
                            <Button
                              onClick={shareToWhatsAppPrivate}
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                                background: "white",
                                color: "#000",
                              }}
                              className={classes.sharebtn}
                            >
                              <CiShare2
                                style={{
                                  color: "green",
                                  fontWeight: "500",
                                  fontSize: "30px",
                                  marginRight: "10px",
                                }}
                              />
                              <Trans>Share</Trans>
                            </Button>
                          </div>} */}
                        </div>
                      )}
                    </div>
                  )}

                  {jobData?.jobPostingTypeId == 119 && (
                    <div
                      className={classes.buttonsection2}
                      style={{
                        display: "flex",
                        marginRight: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          marginLeft: "5px",
                          marginRight: "5px",
                          color: "#fff",
                          width: "100%",
                        }}
                      >
                        {/* <a href={jobData?.jobThirdpartyApplyLink}> */}
                        <Button
                          onClick={handleRedirectLogin}
                          w="100%"
                          style={{
                            marginLeft: "5px",
                            marginRight: "5px",
                            border: "none",
                            cursor: "pointer",
                            background: "#38A78A",
                            color: "#fff",
                          }}
                        >
                          {" "}
                          <Trans>Visit Link</Trans>
                        </Button>
                        {/* </a> */}
                      </div>
                      {/* {<div className={classes.sharesection}>
                        <Button
                          onClick={shareToWhatsAppGovt}
                          style={{
                            marginLeft: "5px",
                            marginRight: "5px",
                            background: "white",
                            color: "#000",
                          }}
                          className={classes.sharebtn}
                        >
                          <CiShare2
                            style={{
                              color: "green",
                              fontWeight: "500",
                              fontSize: "30px",
                              marginRight: "10px",
                            }}
                          />
                          <Trans>Share</Trans>
                        </Button>
                      </div>} */}
                    </div>
                  )}

                  {jobData?.jobPostingTypeId == 9732 && (
                    <div
                      className={classes.buttonsection2}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          marginLeft: "5px",
                          marginRight: "15px",
                          color: "#fff",
                          width: "100%",
                        }}
                      >
                        {/* <a href={jobData?.jobThirdpartyApplyLink}> */}
                        <Button
                          onClick={handleRedirectLogin}
                          w="100%"
                          style={{
                            marginLeft: "5px",
                            marginRight: "5px",
                            border: "none",
                            cursor: "pointer",
                            background: "#034078",
                            color: "#fff",
                            width: "100%",
                          }}
                        >
                          {" "}
                          <Trans>More Information</Trans>
                        </Button>
                        {/* </a> */}
                      </div>

                      {/* <div className={classes.sharesection}>
                        <Button
                          onClick={shareToWhatsAppWalkIn}
                          style={{
                            marginLeft: "5px",
                            marginRight: "5px",
                            background: "white",
                            color: "#000",
                          }}
                          className={classes.sharebtn}
                        >
                          <CiShare2
                            style={{
                              color: "green",
                              fontWeight: "500",
                              fontSize: "30px",
                              marginRight: "10px",
                            }}
                          />
                          <Trans>Share</Trans>
                        </Button>
                      </div> */}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <Center>
            <Loader style={{ marginTop: "220px" }} color="#034078" size={30} />
          </Center>
        )}
      </div>
      {
        saveUrl && <SavePrevUrl />
      }
    </>
  );
};

export default JobDescription;
