import React from 'react'
import { useSearchResultsQuery } from '../redux/api/apiSlice'
import { useSelector } from 'react-redux'
import styles from '../styles/Jobsearchresult.module.css'
import { Loader, Text } from '@mantine/core';
import { ScrollArea, Box } from '@mantine/core';
import AutocompleteLoading from './AutoCompleteSearch';
import { Trans } from '@lingui/macro';
import GovtJobSearchCard from './GovtJobSearchCard';
import JobSearchCard from './JobSearchCard';
import WalkinJobSearchCard from './WalkinJobSearchCard';
import { useNavigation } from '../context/NavigationContext';
import { useEffect } from 'react';

const JobSearchResult = () => {
    const token = useSelector((state) => state?.user?.token)
    const searchParams = new URLSearchParams(window.location.search);
    const searchParamsData = encodeURIComponent(searchParams.get('searchtext'));
    const { prevUrl, resetPrevUrl } = useNavigation() || { prevUrl: '/' };


    const candidateId = localStorage.getItem('candidateId')

    const { data, refetch, isFetching, isLoading } = useSearchResultsQuery({ candidateId, searchParamsData }, {
        skip: !token
    })



    const walkInJobs = data?.WalkInJobs
    const GovermentJobs = data?.GovernmentJobs
    const Jobs = data?.Jobs

    useEffect(() => {
        resetPrevUrl()
    }, [])

    return (
        <>
            <AutocompleteLoading />
            <div className={styles.container}>
                {
                    isLoading ? <Loader color="blue" style={{ marginLeft: "45%", marginTop: "50%" }} /> : <div style={{ minHeight: "60rem" }}>

                        {/* Jobs */}
                        <div>
                            <span className={styles.card_container_heading}><Trans>Jobs</Trans></span>


                            <div className={styles.card_container}>
                                {Jobs?.map((item) => (
                                    <JobSearchCard
                                        key={item?.id}
                                        id={item?.id}
                                        title={item?.jobTitle}
                                        company={item?.companyName}
                                        trade={item?.trade?.masterName}
                                        location={item?.location?.masterName}
                                        state={item?.location?.parentModel?.masterName}
                                        experience={item?.experience?.masterName}
                                        salary={item?.salary?.masterName}
                                        itemTypeId={item?.itemPostingTypeId}
                                        logo={item?.logoVirtualPath ? item?.logoVirtualPath : item?.employer?.logoVirtualPath}
                                    />
                                ))}
                            </div>

                        </div>


                        {/* Govt job */}
                        <div>
                            <span className={styles.card_container_heading}><Trans>Govt. Jobs</Trans></span>


                            <div className={styles.card_container}>
                                {GovermentJobs?.map((item) => (
                                    <GovtJobSearchCard
                                        id={item?.id}
                                        title={item?.jobTitle}
                                        company={item?.companyName}
                                        trade={item?.trade?.masterName}
                                        location={item?.location?.masterName}
                                        state={item?.location?.parentModel?.masterName}
                                        experience={item?.experience?.masterName}
                                        salary={item?.salary?.masterName}
                                        logo={item?.logoVirtualPath ? item?.logoVirtualPath : item?.employer?.logoVirtualPath}

                                    />
                                ))}
                            </div>

                        </div>

                        {/* Walk-In-Jobs */}
                        <div>
                            <span className={styles.card_container_heading}><Trans>Walk-In Jobs</Trans></span>

                            <div className={styles.card_container}>
                                {walkInJobs?.map((item) => (
                                    <WalkinJobSearchCard
                                        id={item?.id}
                                        title={item?.jobTitle}
                                        company={item?.companyName}
                                        trade={item?.trade?.masterName}
                                        location={item?.location?.masterName}
                                        state={item?.location?.parentModel?.masterName}
                                        experience={item?.experience?.masterName}
                                        salary={item?.salary?.masterName}
                                        logo={item?.logoVirtualPath}
                                    />
                                ))}
                            </div>

                        </div>

                    </div>
                }
            </div>
        </>
    )
}

export default JobSearchResult