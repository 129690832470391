import React, { useState, useEffect } from 'react'
import { Skeleton } from '@mantine/core'
import PublicJobSearchCard from './PublicJobSearchCard'
import SavePrevUrl from './SavePrevUrl'



const Publicjobsearchresult = () => {

    const searchParams = new URLSearchParams(window.location.search);
    const searchParamsData = searchParams.get('searchtext');
    const [alljobs, setAllJobs] = useState([])
    const [loading, setloading] = useState(true)




    useEffect(() => {
        const fetchjobs = async () => {
            const response = await fetch(`${import.meta.env.VITE_BASE_URL}/public/jobs/search?searchText=${searchParamsData}`)
            const data = await response.json();
            console.log("data-", data)
            setAllJobs(data)
            setloading(false)
        }

        fetchjobs();
    }, [])






    return (
        <div style={{ width: "100%", backgroundColor: "#f6f6f6", padding: "10px 10px", minHeight: "100vh", paddingBottom: "65px" }}>
            {loading ? (
                <>
                    <div style={{ marginBottom: "20px" }}>
                        <Skeleton height={50} circle mb="xl" />
                        <Skeleton height={8} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} width="100%" radius="xl" />
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                        <Skeleton height={50} circle mb="xl" />
                        <Skeleton height={8} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} width="100%" radius="xl" />
                    </div>
                    <div>
                        <Skeleton height={50} circle mb="xl" />
                        <Skeleton height={8} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} width="100%" radius="xl" />
                    </div>
                </>
            ) : (
                <>
                    {alljobs.length > 0 && (
                        alljobs.map(job => {
                            return <PublicJobSearchCard
                                id={job?.id}
                                title={job?.jobTitle}
                                company={job?.companyName}
                                trade={job?.trade?.masterName}
                                location={job?.location?.masterName}
                                state={job?.location?.parentModel?.masterName}
                                education={job?.education?.masterName}
                                experience={job?.experience?.masterName}
                                salary={job?.salary?.masterName}
                                logo={job?.logoVirtualPath ? job?.logoVirtualPath : job?.employer?.logoVirtualPath}
                            />

                        })
                    )}

                </>
            )}

        </div>
    )





}

export default Publicjobsearchresult